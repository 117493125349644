var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Container } from '@mui/material';
import NavBar from 'routing/NavBar';
import ProtectedRoute from 'routing/ProtectedRoute';
import { ApiClient, ApiClientContext } from 'common/api';
import routesDef from 'routing/routes';
function App() {
    var _a = useAuth0(), isLoading = _a.isLoading, getAccessTokenSilently = _a.getAccessTokenSilently, getAccessTokenWithPopup = _a.getAccessTokenWithPopup;
    var _b = useState(), apiClient = _b[0], setApiClient = _b[1];
    useEffect(function () {
        if (getAccessTokenSilently !== undefined && getAccessTokenWithPopup !== undefined) {
            if (!apiClient) {
                setApiClient(new ApiClient(getAccessTokenSilently, getAccessTokenWithPopup));
            }
            else {
                apiClient.updateTokenGetters(getAccessTokenSilently, getAccessTokenWithPopup);
            }
        }
    }, [getAccessTokenSilently, getAccessTokenWithPopup]);
    var protectedRoutes = routesDef.map(function (route) {
        if (route.restricted) {
            return __assign(__assign({}, route), { element: (React.createElement(ProtectedRoute, { restricted: route.restricted }, route.element)) });
        }
        return route;
    });
    var routes = useRoutes(protectedRoutes);
    if (isLoading) {
        return (React.createElement("div", null,
            React.createElement(NavBar, null),
            React.createElement(Container, { sx: {
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                } },
                React.createElement(CircularProgress, null))));
    }
    return (React.createElement(Box, null,
        React.createElement(NavBar, null),
        React.createElement(ApiClientContext.Provider, { value: apiClient }, routes)));
}
export default App;
