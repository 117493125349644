var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Container, Stack, Paper, Grid } from '@mui/material';
import useSWR from 'swr';
import { ApiClientContext } from 'common/api';
import WorkflowStatusChip from 'components/WorkflowStatusChip';
/*
Display meta data for request in a table. Preview images, allow them to be editied (add new images, remove images, reorder?). Delete the workflow
*/
var ReqDetail = function (props) {
    var id = useParams().id;
    var apiClient = useContext(ApiClientContext);
    var _a = useSWR("/api/workflows/".concat(id), function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, apiClient.getWorkflow(id)];
    }); }); }), data = _a.data, error = _a.error;
    var _b = useState([]), images = _b[0], setImages = _b[1];
    var _c = useState(false), imagesLoading = _c[0], setImagesLoading = _c[1];
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.dicoms) {
            setImagesLoading(true);
            var promises = data.dicoms.map(function (dicom) {
                return apiClient.getDicomFile(id, dicom);
            });
            Promise.all(promises).then(function (imgBlobs) {
                //console.log(imgBlobs)
                setImages(imgBlobs);
                setImagesLoading(false);
            });
        }
    }, [data]);
    if (error) {
        return React.createElement("div", null, "ERROR No workflow bruh");
    }
    if (!data) {
        return (React.createElement(Container, { sx: {
                width: '100vw',
                height: '90vh',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            } },
            React.createElement(CircularProgress, null)));
    }
    return (React.createElement(Stack, { sx: { margin: 5 } },
        React.createElement(Typography, { variant: "h4", component: "h1" },
            React.createElement("strong", null, "Request: "),
            id,
            " ",
            React.createElement(WorkflowStatusChip, { status: data.workflow_status })),
        React.createElement(Typography, { variant: "h6" },
            React.createElement("strong", null, "Org: "),
            " ",
            data.org_id),
        data.dicoms &&
            React.createElement(Grid, { container: true, spacing: { xs: 2, md: 3 }, columns: { xs: 4, sm: 8, md: 12 } }, data === null || data === void 0 ? void 0 : data.dicoms.map(function (_, index) { return (React.createElement(Grid, { item: true, xs: 2, sm: 4, md: 4, key: "dicom-".concat(index) },
                React.createElement(Paper, { sx: { display: 'flexbox', justifyContent: 'center' } },
                    images.length > index && React.createElement("img", { src: images[index], alt: "dicom image" }),
                    images.length <= index && React.createElement(CircularProgress, null)))); })),
        !data.dicoms &&
            React.createElement(Typography, null, "No Dicoms to display")));
};
export default ReqDetail;
