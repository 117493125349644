import React from "react";
import { useParams } from "react-router-dom";
import VTFAnalysis from "components/vtf";
import "./SegAnalysis.css";
function SegAnalysis() {
    var id = useParams().id;
    return (React.createElement("div", { className: "seg-analysis-container" },
        React.createElement(VTFAnalysis, null)));
}
export default SegAnalysis;
