import React, { useEffect, useState, useCallback } from "react";
import Icon from "./Icon";
import { useSquence } from "../utils/SequenceProvider";
var DELAY = 100; // milliseconds from each image to another
var VideoControls = function (_a) {
    var currentSequenceData = _a.currentSequenceData, currentIndex = _a.currentIndex, setCurrentIndex = _a.setCurrentIndex;
    var provider = useSquence();
    var numStep = currentSequenceData.numStep;
    var _b = useState(false), playing = _b[0], setPlaying = _b[1];
    var _c = useState(false), playingback = _c[0], setPlayingBack = _c[1];
    var next = useCallback(function (ev, index) {
        if (index === void 0) { index = null; }
        var next = (index ? index : currentIndex) + 1;
        setCurrentIndex(next === numStep ? 0 : next);
    }, [currentIndex, numStep, setCurrentIndex]);
    var prev = useCallback(function (ev, index) {
        if (index === void 0) { index = null; }
        var prev = (index ? index : currentIndex) - 1;
        setCurrentIndex(prev < 0 ? numStep - 1 : prev);
    }, [currentIndex, numStep, setCurrentIndex]);
    var handlePrev = function () {
        if (playing)
            setPlaying(false);
        if (playingback)
            setPlayingBack(false);
        var prev = currentIndex - 1;
        setCurrentIndex(prev < 0 ? numStep - 1 : prev);
    };
    var handleEnd = function () {
        if (playing)
            setPlaying(false);
        if (playingback)
            setPlayingBack(false);
        setCurrentIndex(numStep - 1);
    };
    var handleStart = function () {
        if (playing)
            setPlaying(false);
        if (playingback)
            setPlayingBack(false);
        setCurrentIndex(0);
    };
    var handleBackPlay = function () {
        setPlayingBack(true);
        setPlaying(false);
    };
    var handlePlay = function () {
        setPlayingBack(false);
        setPlaying(true);
    };
    var handlePause = function () {
        setPlaying(false);
        setPlayingBack(false);
    };
    var handleNext = function () {
        if (playing)
            setPlaying(false);
        if (playingback)
            setPlayingBack(false);
        var next = currentIndex + 1;
        setCurrentIndex(next === numStep ? 0 : next);
    };
    useEffect(function () {
        var interval = null;
        if (playing) {
            interval = setInterval(next, DELAY);
        }
        if (playingback) {
            interval = setInterval(prev, DELAY);
        }
        if (!playing && !playingback && interval) {
            clearInterval(interval);
            interval = null;
        }
        return function () { return clearInterval(interval); };
    }, [playing, playingback, currentIndex, next, prev]);
    return (React.createElement("div", { className: "text-xs text-center text-white flex space-x-4 justify-center flex-1" },
        React.createElement(Icon, { name: "Kip-to-beginning", onClick: handleStart, size: "lg" }),
        React.createElement(Icon, { name: "Rewind", onClick: handlePrev, size: "lg" }),
        playingback ? (React.createElement(Icon, { name: "Pause", onClick: handlePause, size: "lg" })) : (React.createElement(Icon, { name: "Play", onClick: handleBackPlay, size: "lg", className: "rotate-180" })),
        playing ? (React.createElement(Icon, { name: "Pause", onClick: handlePause, size: "lg" })) : (React.createElement(Icon, { name: "Play", onClick: handlePlay, size: "lg" })),
        React.createElement(Icon, { name: "Forward", onClick: handleNext, size: "lg" }),
        React.createElement(Icon, { name: "Skip-to-end", onClick: handleEnd, size: "lg" })));
};
export default VideoControls;
