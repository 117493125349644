import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
var ProtectedRoute = function (props) {
    var _a = useAuth0(), user = _a.user, isLoading = _a.isLoading;
    var location = useLocation();
    if (!user) {
        return React.createElement(Navigate, { to: "/", state: { from: location } });
    }
    var ROLES = process.env.AUTH0_CLAIM_ROLES;
    var userRoles = user[ROLES];
    if (props.restricted.length > 0) {
        var sharedperms = userRoles.filter(function (n) { return props.restricted.indexOf(n) !== -1; });
        if (sharedperms.length === 0) {
            return React.createElement(Navigate, { to: "/", state: { from: location } });
        }
    }
    return (React.createElement(React.Fragment, null, props.children));
};
export default ProtectedRoute;
