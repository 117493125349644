import React from 'react';
import { Box } from '@mui/material';
import './Home.css';
import background from "../../public/ArcusBackgroundWithTitle.png";
import AuthenticationButton, { AuthButtons } from 'components/AuthenticationButton';
function Home() {
    return (React.createElement(Box, { style: { padding: 0 } },
        React.createElement("div", { className: "BkgrdImg", style: { backgroundImage: "url(".concat(background, ")") } },
            React.createElement("div", { className: "TitlesContainer" },
                React.createElement(Box, { sx: { padding: '10px' } },
                    React.createElement(AuthenticationButton, { variant: "contained", color: "primary.contrastText", showButton: AuthButtons.LOGIN }))))));
}
export default Home;
