var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Button, IconButton, Paper, Popover, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
var Item = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff' }, theme.typography.body2), { padding: theme.spacing(1), textAlign: "center", color: theme.palette.text.secondary }));
});
function Profile() {
    var _a = useAuth0(), user = _a.user, logout = _a.logout;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(Box, null,
        React.createElement(IconButton, { "aria-describedby": id, onClick: handleClick },
            React.createElement(Avatar, { src: user.picture, alt: "Avatar" })),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            } },
            React.createElement(Box, { sx: {} },
                React.createElement(Stack, { spacing: 2, alignItems: "center" },
                    React.createElement(Item, null,
                        React.createElement(Avatar, { alt: "Avatar", src: user.picture, sx: { width: 56, height: 56 } })),
                    React.createElement(Item, null,
                        React.createElement(Typography, { sx: { fontSize: 18, color: "#000000" } }, user.name),
                        React.createElement(Typography, { sx: { fontSize: 14 } }, user.email)),
                    React.createElement(Item, null,
                        React.createElement(Button, { variant: "outlined", sx: { color: "primary" }, onClick: function () { return logout({
                                returnTo: window.location.origin,
                            }); } }, "Logout")))))));
}
;
var ProfileButton = function () {
    var isAuthenticated = useAuth0().isAuthenticated;
    return isAuthenticated ? React.createElement(Profile, null) : React.createElement(React.Fragment, null);
};
export default ProfileButton;
