import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Label } from 'recharts';
import { Typography } from '@mui/material';
function getRandomColor() {
    var letters = "0123456789ABCDEF";
    // html color code starts with #
    var color = '#';
    // generating 6 times as HTML color code consist
    // of 6 letter or digits
    for (var i = 0; i < 6; i++)
        color += letters[(Math.floor(Math.random() * 16))];
    return color;
}
var data = [{ name: 'Page A', uv: 400, pv: 2400, amt: 2400 }];
var VtfAnalysisChart = function (props) {
    props.yDataSets.forEach(function (yDataSet) {
        if (yDataSet.length !== props.xData.length) {
            throw Error('Invalid ydata dimensions in graph.');
        }
    });
    var dataPoints = props.xData.map(function (x, index) {
        x = Number(x.toFixed(2));
        var interumPoint = { x: x };
        props.yDataSets.forEach(function (yDataSet, yindex) {
            yDataSet[index] = Number(yDataSet[index].toFixed(2));
            interumPoint["line".concat(yindex)] = yDataSet[index];
        });
        return interumPoint;
    });
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: "h6" }, props.title),
        React.createElement("div", { style: { overflowX: "auto" } },
            React.createElement(LineChart, { width: 500, height: 300, data: dataPoints, margin: { top: 5, right: 20, bottom: 5, left: 0 } },
                props.yDataSets.map(function (yData, index) { return (React.createElement(Line, { type: "monotone", dataKey: "line".concat(index), stroke: props.lineColor, key: index })); }),
                React.createElement(CartesianGrid, { stroke: "#ccc", strokeDasharray: "5 5" }),
                React.createElement(XAxis, { dataKey: "x", type: "number", domain: [0, function (dataMax) { return (Math.floor(dataMax / 100) * 100 + 100); }] },
                    React.createElement(Label, { value: props.xAxisLabel, offset: 0, position: "insideBottom" })),
                React.createElement(YAxis, null,
                    React.createElement(Label, { value: props.yAxisLabel, angle: -90, position: "insideLeft" })),
                React.createElement(Tooltip, null)))));
};
export default React.memo(VtfAnalysisChart);
