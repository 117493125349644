var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from "react";
import { Stage, Layer, Image, Circle, Transformer, Line, Group, } from "react-konva";
import Konva from "konva";
import { isArray } from "lodash";
// const distance = (x1, y1, x2, y2) => { // to set limit on points drag
//   return Math.pow(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2), 0.5);
// };
var ImageCanvas = function (_a) {
    var images = _a.images, index = _a.index, currentSequenceId = _a.currentSequenceId, proccessedVesselColor = _a.proccessedVesselColor, canvasConfig = _a.canvasConfig, setCanvasConfig = _a.setCanvasConfig, setSegmentationChanged = _a.setSegmentationChanged, placeCircleEnabled = _a.placeCircleEnabled, currentSequenceData = _a.currentSequenceData, setSeriesAssociation = _a.setSeriesAssociation, segmentation = _a.segmentation, overrideSegmentation = _a.overrideSegmentation, setOverrideSegmentation = _a.setOverrideSegmentation, filters = _a.filters;
    var _b = useState(null), image = _b[0], setImage = _b[1];
    var img = new window.Image();
    var imageRef = useRef();
    var circleRef = useRef(null);
    var groupRef = useRef(null);
    var trRef = useRef();
    img.src = images[index];
    var handleLoad = function () {
        if (image === null || image.src !== img.src) {
            setImage(img);
            img.removeEventListener("load", handleLoad);
        }
    };
    img.addEventListener("load", handleLoad);
    useEffect(function () {
        if (index === null) {
            setImage(null);
        }
    }, [index]);
    useEffect(function () {
        if (image) {
            imageRef.current.cache();
        }
    }, [image]);
    var handleDragEnd = function (e) {
        setCanvasConfig(__assign(__assign({}, canvasConfig), { image: {
                x: e.target.x(),
                y: e.target.y(),
            } }));
        //  Relocate the group
        if (groupRef.current) {
            setCanvasConfig(__assign(__assign({}, canvasConfig), { groupRef: {
                    x: e.target.x(),
                    y: e.target.y(),
                } }));
        }
    };
    var getMin = function (image, container) {
        var minX = 0;
        var minY = 0;
        var imageWidth = image.width() * image.getScaleX();
        var containerWidth = container.width();
        var containerHeight = container.height();
        if (imageWidth > containerHeight)
            minY = containerHeight - imageWidth;
        else
            minY = 0;
        if (imageWidth > containerWidth)
            minX = containerWidth - imageWidth;
        else
            minX = 0;
        return {
            x: minX,
            y: minY,
        };
    };
    var getMax = function (image, container) {
        var imageWidth = image.width() * image.getScaleX();
        var containerWidth = container.width();
        var containerHeight = container.height();
        var maxX = containerWidth - imageWidth;
        var maxY = containerHeight - imageWidth;
        if (imageWidth > containerWidth)
            maxX = containerWidth - (imageWidth - containerWidth);
        if (imageWidth > containerHeight)
            maxY = containerHeight - (imageWidth - containerHeight);
        return {
            x: maxX,
            y: maxY,
        };
    };
    var handleDragMove = function (ev) {
        var target = ev.target;
        var newPos = {
            x: target.x(),
            y: target.y(),
        };
        var min = getMin(target, target.parent);
        var max = getMax(target, target.parent);
        if (newPos.x > max.x)
            newPos.x = max.x;
        if (newPos.y > max.y)
            newPos.y = max.y;
        if (newPos.x < min.x) {
            newPos.x = min.x;
        }
        if (newPos.y < min.y) {
            newPos.y = min.y;
        }
        target.setAbsolutePosition(newPos);
        var newState = __assign(__assign({}, canvasConfig), { image: newPos, circle: __assign(__assign({}, canvasConfig.circle), { x: canvasConfig.circle.x + (newPos.x - canvasConfig.image.x), y: canvasConfig.circle.y + (newPos.y - canvasConfig.image.y) }) });
        // Update Circle too ?
        if (groupRef.current) {
            newState = __assign(__assign({}, newState), { groupRef: {
                    x: newPos.x,
                    y: newPos.y,
                } });
        }
        setCanvasConfig(newState);
    };
    var handleMouseEnter = function (ev) {
        if (canvasConfig.draggable)
            return;
        var parent = ev.target.parent;
        var container = parent.parent.container();
        container.style.cursor = "crosshair";
    };
    var handleMouseLeave = function (ev) {
        if (canvasConfig.draggable)
            return;
        var parent = ev.target.parent;
        var container = parent.parent.container();
        container.style.cursor = null;
    };
    var handleMouseMove = function (ev) {
        if (!canvasConfig.circle.placed && !canvasConfig.draggable) {
            var _a = ev.evt, layerX = _a.layerX, layerY = _a.layerY;
            setCanvasConfig(__assign(__assign({}, canvasConfig), { circle: __assign(__assign({}, canvasConfig.circle), { x: layerX, y: layerY, radius: 23, visible: placeCircleEnabled }) }));
            var parent_1 = ev.target.parent;
            var container = parent_1.parent.container();
            container.style.cursor = "crosshair";
            return;
        }
        else {
            var parent_2 = ev.target.parent;
            var container = parent_2.parent.container();
            container.style.cursor = null;
        }
    };
    var handleMouseUp = function (ev) {
        if (canvasConfig.draggable ||
            canvasConfig.circle.placed ||
            !placeCircleEnabled)
            return;
        setIsSelected(true);
        var _a = ev.evt, layerX = _a.layerX, layerY = _a.layerY;
        setCanvasConfig(__assign(__assign({}, canvasConfig), { circle: __assign(__assign({}, canvasConfig.circle), { x: layerX, y: layerY, radius: 23, visible: true, placed: true }) }));
    };
    var _c = useState(false), isSelected = _c[0], setIsSelected = _c[1];
    var handleTransformEnd = function (ev) {
        var node = circleRef.current;
        var scale = node.getScaleX();
        node.scaleX(1);
        node.scaleY(1);
        setCanvasConfig(__assign(__assign({}, canvasConfig), { circle: __assign(__assign({}, canvasConfig.circle), { radius: canvasConfig.circle.radius * scale }) }));
    };
    useEffect(function () {
        if (isSelected &&
            segmentation.length === 0 &&
            canvasConfig.circle.visible &&
            canvasConfig.circle.placed) {
            trRef.current.nodes([circleRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected, segmentation, canvasConfig]);
    // let points = [];
    var _d = useState([]), points = _d[0], setPoints = _d[1];
    var handlePointDragMove = function (ev, i) {
        var x = ev.target.x();
        var y = ev.target.y();
        // Limit
        var p = __spreadArray([], points, true);
        p[i * 2] = x;
        p[i * 2 + 1] = y;
        setPoints(p);
    };
    var handlePointDragEnd = function (ev, i) {
        var _a, _b;
        var x = ev.target.x();
        var y = ev.target.y();
        var ratio = filters.zoom.current / 50;
        var newDots = [];
        if (!overrideSegmentation[currentSequenceId][index]) {
            // Init
            newDots = __spreadArray([], segmentation[index], true);
        }
        else {
            newDots = __spreadArray([], overrideSegmentation[currentSequenceId][index], true);
        }
        newDots[i] = [x / ratio, y / ratio];
        //newDots[i] = [x, y];
        setOverrideSegmentation(__assign(__assign({}, overrideSegmentation), (_a = {}, _a[currentSequenceId] = __assign(__assign({}, overrideSegmentation[currentSequenceId]), (_b = {}, _b[index] = newDots, _b)), _a)));
        setSegmentationChanged(true);
        setSeriesAssociation(function (prev) {
            return prev.map(function (previousAssociation) {
                return previousAssociation.associatedSeries.mriLabel === currentSequenceData.mriLabel
                    ? __assign(__assign({}, previousAssociation), { completed: false }) : __assign({}, previousAssociation);
            });
        });
    };
    var ratio = filters.zoom.current / 50;
    var _e = useState(), circleSource = _e[0], setCircleSource = _e[1];
    useEffect(function () {
        var p = [];
        setCircleSource(currentSequenceId in overrideSegmentation && overrideSegmentation[currentSequenceId][index]
            ? overrideSegmentation[currentSequenceId][index]
            : segmentation[index]);
        if (segmentation.length > 0) {
            var isOverrideSegmentation = currentSequenceId in overrideSegmentation;
            var source = isOverrideSegmentation && overrideSegmentation[currentSequenceId][index]
                ? overrideSegmentation[currentSequenceId][index]
                : segmentation[index];
            if (source && isArray(source)) {
                source.map(function (dot) {
                    var x = dot[0], y = dot[1];
                    p.push(x * ratio);
                    p.push(y * ratio);
                    // p.push(x);
                    // p.push(y);
                    return dot;
                });
                setPoints(__spreadArray([], p, true));
            }
        }
    }, [overrideSegmentation, segmentation, index, ratio]);
    var handlePointMouseEnter = function (ev) {
        var container = ev.target.parent.parent.parent.container();
        container.style.cursor = "pointer";
    };
    var handlePointMouseLeave = function (ev) {
        var container = ev.target.parent.parent.parent.container();
        container.style.cursor = "crosshair";
    };
    return (image && (React.createElement("div", null,
        React.createElement(Stage, { width: 580, height: 580 },
            React.createElement(Layer, null,
                React.createElement(Image, { x: canvasConfig.image.x, y: canvasConfig.image.y, draggable: canvasConfig.draggable, ref: imageRef, image: image, width: 256, height: 256, scaleX: 580 / 256 * (filters.zoom.current / 50), scaleY: 580 / 256 * (filters.zoom.current / 50), contrast: filters.contrast.current, brightness: filters.brightness.current / 100, filters: [Konva.Filters.Brighten, Konva.Filters.Contrast], onDragMove: handleDragMove, onDragEnd: handleDragEnd, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onMouseUp: handleMouseUp, onMouseMove: handleMouseMove }),
                segmentation.length > 0 && (React.createElement(Group, { ref: groupRef, x: canvasConfig.groupRef.x, y: canvasConfig.groupRef.y },
                    React.createElement(Line, { points: points, tension: 0.5, closed: true, fill: proccessedVesselColor, opacity: 0.2, stroke: proccessedVesselColor }),
                    circleSource &&
                        circleSource.map(function (dot, index) {
                            var x = dot[0], y = dot[1];
                            return (React.createElement(Circle, { key: "circle-".concat(index), x: (ratio * x), y: (ratio * y), radius: 3, stroke: proccessedVesselColor, fill: proccessedVesselColor, strokeWidth: 1, draggable: true, onMouseEnter: handlePointMouseEnter, onMouseLeave: handlePointMouseLeave, onDragEnd: function (ev) { return handlePointDragEnd(ev, index); }, onDragMove: function (ev) { return handlePointDragMove(ev, index); }, className: "segmentationCircles" }));
                        }))),
                canvasConfig.circle.visible && segmentation.length === 0 && (React.createElement(React.Fragment, null,
                    React.createElement(Circle, { onMouseUp: handleMouseUp, onMouseMove: handleMouseMove, onTransformEnd: handleTransformEnd, ref: circleRef, x: canvasConfig.circle.x, y: canvasConfig.circle.y, radius: canvasConfig.circle.radius, stroke: proccessedVesselColor, opacity: canvasConfig.circle.placed ? 0.2 : 1, strokeWidth: 2, dash: canvasConfig.circle.placed ? [0] : [2, 4], fill: canvasConfig.circle.placed ? proccessedVesselColor : "transparent" }),
                    isSelected && (React.createElement(Transformer, { ref: trRef, rotateEnabled: false, centeredScaling: true, enabledAnchors: [
                            "top-left",
                            "top-right",
                            "bottom-left",
                            "bottom-right",
                        ], boundBoxFunc: function (oldBox, newBox) {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        } })))))))));
};
export default ImageCanvas;
