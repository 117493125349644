import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import NotificationBoundary from 'common/context_providers/NotificationBoundary';
import './index.css';
import { Box } from '@mui/system';
var theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#424242',
            contrastText: '#ffff'
        },
        secondary: {
            main: '#0195d6',
            contrastText: '#ffff'
        },
        warning: {
            main: '#e04040',
            contrastText: '#ffff'
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingLeft: '0',
                    paddingRight: '0'
                }
            }
        }
    }
});
var Auth0ProviderWithHistory = function (props) {
    var domain = process.env.AUTH0_APP_DOMAIN || 'domain';
    var clientId = process.env.AUTH0_APP_CLIENT_ID || 'clientId';
    var audience = process.env.AUTH0_API_AUDIENCE || 'audience';
    var navigate = useNavigate();
    var onRedirectCallback = function (appState) {
        navigate((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    };
    return (React.createElement(Auth0Provider, { domain: domain, clientId: clientId, audience: audience, redirectUri: window.location.origin, onRedirectCallback: onRedirectCallback, useRefreshTokens: true }, props.children));
};
ReactDOM.render(React.createElement(ThemeProvider, { theme: theme },
    React.createElement(BrowserRouter, null,
        React.createElement(Auth0ProviderWithHistory, null,
            React.createElement(NotificationBoundary, null,
                React.createElement(Box, { sx: { display: 'flex', minHeight: '100vh', flexDirection: 'column' } },
                    React.createElement(Box, { sx: { flex: 1 } },
                        React.createElement(App, null))))))), document.getElementById('app-root'));
