var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useCallback } from "react";
import Sequence from "./Sequence";
import { useSquence } from "../utils/SequenceProvider";
var Series = function (_a) {
    var data = _a.data, workflow = _a.workflow, setWorkflow = _a.setWorkflow, canvasConfig = _a.canvasConfig, setCanvasConfig = _a.setCanvasConfig, segmentationChanged = _a.segmentationChanged, setSegmentationChanged = _a.setSegmentationChanged, setPlaceCircleEnabled = _a.setPlaceCircleEnabled, currentSequenceData = _a.currentSequenceData, setCurrentSequenceData = _a.setCurrentSequenceData, currentSequenceId = _a.currentSequenceId, setCurrentSequenceId = _a.setCurrentSequenceId, vesselConfig = _a.vesselConfig, setVesselConfig = _a.setVesselConfig, seriesAssociation = _a.seriesAssociation, setSeriesAssociation = _a.setSeriesAssociation, segmentation = _a.segmentation, setSegmentation = _a.setSegmentation, overrideSegmentation = _a.overrideSegmentation, setOverrideSegmentation = _a.setOverrideSegmentation, currentIndex = _a.currentIndex, setCurrentIndex = _a.setCurrentIndex, clear = _a.clear, filters = _a.filters;
    var provider = useSquence();
    var associatedVesselIndex = provider.associatedVesselIndex, findAssociationBySeries = provider.findAssociationBySeries, findAssociationByVessel = provider.findAssociationByVessel;
    var _b = useState(false), displayMenu = _b[0], setDisplayMenu = _b[1];
    var _c = useState(false), displayJump = _c[0], setDisplayJump = _c[1];
    var _d = useState(currentIndex + 1), jumpValue = _d[0], setJumpValue = _d[1];
    var handleSelect = function (ev, key) {
        ev.preventDefault();
        if (key !== currentSequenceId) {
            setSequence(key);
            setCurrentIndex(0);
            setCanvasConfig(__assign(__assign({}, canvasConfig), { circle: __assign(__assign({}, canvasConfig.circle), { placed: false, visible: false }) }));
        }
        setDisplayMenu(false);
    };
    var _e = useState(undefined), highlightVessel = _e[0], setHighlightVessel = _e[1];
    var handleTypeChange = function (index) {
        //   Update the Provider (?)
        setHighlightVessel(index);
    };
    var range = function (start, stop, step) {
        return Array.from({ length: (stop - start) / step + 1 }, function (_, i) { return start + i * step; });
    };
    var _f = useState([]), selected = _f[0], setSelected = _f[1];
    var handleSeriesChange = function (e, name, index) {
        handleTypeChange(index);
        setSelected([]);
        var association = findAssociationByVessel(name, seriesAssociation);
        if (!association)
            return;
        var associatedSeries = association.associatedSeries;
        if (!!association) {
            handleSelect(e, associatedSeries.id);
        }
    };
    var setSequence = useCallback(function (selected) {
        setCurrentSequenceId(selected);
        var currentSequence = data.find(function (sec) {
            var keys = Object.keys(sec);
            if (!selected || keys[0] === selected) {
                return true;
            }
            return false;
        });
        setCurrentSequenceData(currentSequence[selected]);
        var association = findAssociationBySeries(currentSequence[selected].mriLabel, seriesAssociation);
        setPlaceCircleEnabled(false);
        if (association) {
            var associatedTo = association.associatedTo;
            var vesselIndex_1 = associatedVesselIndex(associatedTo.name, currentSequenceData, seriesAssociation);
            association.segmentation &&
                setSegmentation(__spreadArray([], association.segmentation, true));
            setVesselConfig(function () {
                return association.segmentation.length
                    ? { selected: null, proccessed: vesselIndex_1, completed: null }
                    : {
                        proccessed: null,
                        selected: vesselIndex_1 && vesselIndex_1.toString(),
                        completed: null,
                    };
            });
            handleTypeChange(vesselIndex_1);
        }
        else {
            setVesselConfig({
                selected: null,
                proccessed: null,
                completed: null,
            });
            setSegmentation([]);
        }
    }, [
        seriesAssociation,
        setPlaceCircleEnabled,
        data,
        findAssociationBySeries,
        setCurrentSequenceId,
        setCurrentSequenceData,
        setSegmentation
    ]);
    useEffect(function () {
        if (!currentSequenceId) {
            var firstKeys = Object.keys(data[0]);
            setSequence(firstKeys[0]);
        }
        else {
            setSequence(currentSequenceId);
        }
    }, [data, currentSequenceId, seriesAssociation]);
    if (!data && data.length === 0)
        return React.createElement("div", null, "Loading...");
    var mriLabel = currentSequenceData.mriLabel, numStep = currentSequenceData.numStep; //sequenceData;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "border-gray-500 border-r fill-content m-5" },
            React.createElement("div", { className: "mb-12" },
                React.createElement("div", { className: "flex flex-row space-x-3 items-center" },
                    React.createElement("div", { className: "relative" },
                        React.createElement("button", { className: "cta2 text-sm", onClick: function () { return setDisplayMenu(!displayMenu); } },
                            React.createElement("span", { className: "-rotate-90" },
                                React.createElement("i", { className: " icon-sc-Arrow-Down text-xl leading-none" })),
                            React.createElement("span", null, mriLabel)),
                        React.createElement("ul", { className: "bg-white rounded-sm py-1.5  block cursor-pointer border z-10  border-azure-500 mt-1 absolute w-full text-sm ".concat(displayMenu ? "block" : "hidden") },
                            React.createElement("li", null, "Please select a series"),
                            data.map(function (sequence) {
                                var keys = Object.keys(sequence);
                                var sequenceData = sequence[keys[0]];
                                var mriLabel = sequenceData.mriLabel, numStep = sequenceData.numStep;
                                var foundIndex;
                                return (React.createElement("li", { className: "", key: "key-".concat(keys[0]) },
                                    React.createElement("a", { href: "#/sequence/".concat(keys[0]), onClick: function (ev) { return handleSelect(ev, keys[0]); }, className: "flex flex-row space-x-2 items-center py-2 px-3 hover:bg-gray-100" },
                                        React.createElement("span", { className: "w-3 h-3 border block rounded-full border-gray-600 \n                          ".concat(seriesAssociation.filter(function (association, i) {
                                                if (association.associatedSeries.mriLabel === mriLabel) {
                                                    foundIndex = association;
                                                }
                                                return (association.associatedSeries.mriLabel === mriLabel);
                                            }).length
                                                ? foundIndex &&
                                                    foundIndex.associatedTo &&
                                                    foundIndex.associatedTo.className
                                                : "", "\n                          ") }),
                                        React.createElement("span", null,
                                            mriLabel,
                                            " [",
                                            numStep,
                                            "]"))));
                            }))),
                    React.createElement("div", { className: "text-xs flex flex-row items-center space-x-1" },
                        React.createElement("span", { className: "tooltip-wrapper" },
                            React.createElement("span", { role: "tooltip", className: "inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-gray-600  border rounded-lg shadow-sm transition-opacity duration-300 tooltip" },
                                "\u201CShift\u201D+click to select multiple images.",
                                React.createElement("div", { className: "tooltip-arrow", "data-popper-arrow": "", style: {
                                        position: "absolute",
                                        left: 0,
                                        transform: "translate3d(58px, 0px, 0px)",
                                    } })),
                            React.createElement("i", { className: " icon-sc-Info text-2xl leading-none" })),
                        React.createElement("span", { className: "uppercase" },
                            React.createElement("span", null, "Current"),
                            " ",
                            React.createElement("span", { className: "" },
                                currentIndex + 1,
                                "/",
                                numStep)),
                        React.createElement("span", { className: "separator border-r w-0" }, "\u00A0"),
                        segmentation.length > 0 &&
                            (displayJump ? (React.createElement("form", { className: "flex", onSubmit: function (ev) {
                                    ev.preventDefault();
                                    setCurrentIndex(jumpValue - 1);
                                    setDisplayJump(false);
                                } },
                                React.createElement("input", { type: "number", max: numStep, min: 1, value: jumpValue, onChange: function (ev) {
                                        var newValue = Math.min(parseInt(ev.target.value), numStep);
                                        setJumpValue(newValue);
                                    }, className: "cta" }),
                                React.createElement("button", { type: "submit", className: "px-2" },
                                    React.createElement("i", { className: "icon-sc-Check text-base" })))) : (React.createElement("button", { className: "underline", onClick: function () {
                                    setDisplayJump(true);
                                } }, "Jump to..."))))),
                React.createElement(Sequence, { workflow: workflow, setWorkflow: setWorkflow, canvasConfig: canvasConfig, setCanvasConfig: setCanvasConfig, segmentationChanged: segmentationChanged, setSegmentationChanged: setSegmentationChanged, setPlaceCircleEnabled: setPlaceCircleEnabled, currentSequenceData: currentSequenceData, currentSequenceId: currentSequenceId, vesselConfig: vesselConfig, seriesAssociation: seriesAssociation, setSeriesAssociation: setSeriesAssociation, segmentation: segmentation, setSegmentation: setSegmentation, overrideSegmentation: overrideSegmentation, setOverrideSegmentation: setOverrideSegmentation, currentIndex: currentIndex, setCurrentIndex: setCurrentIndex, setVesselConfig: setVesselConfig, changeSeries: handleSelect, clear: clear, filters: filters, handleTypeChange: handleTypeChange, handleSeriesChange: handleSeriesChange, highlightVessel: highlightVessel, selected: selected, setSelected: setSelected })))));
};
export default Series;
