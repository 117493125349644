var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useContext } from "react";
import ReactSlider from "react-slider";
import Icon from "./Icon";
import { useSquence } from "../utils/SequenceProvider";
import VideoControls from "./VideoControls";
import ImageCanvas from "./ImageCanvas";
import { ApiClientContext } from "common/api";
import { useParams } from "react-router-dom";
var SliderControl = function (_a) {
    var name = _a.name, onChange = _a.onChange, opt = _a.opt, active = _a.active, defaultValue = _a.defaultValue, value = _a.value, maxLabel = _a.maxLabel, minLabel = _a.minLabel, min = _a.min, max = _a.max;
    var _b = useState(active !== null && active !== void 0 ? active : false), show = _b[0], setShow = _b[1];
    var _c = useState(value !== null && value !== void 0 ? value : 0), val = _c[0], setVal = _c[1];
    return !show ? (React.createElement(Icon, { name: name, onClick: function () { return setShow(true); } })) : (React.createElement("div", { className: "absolute w-full h-full top-0 bg-blue-1000  rounded-full m-0 left-0 mt0" },
        React.createElement(Icon, { name: name, onClick: function () { return setShow(false); }, active: show }),
        React.createElement("span", { className: "legend mt-3 mb-1" }, maxLabel !== null && maxLabel !== void 0 ? maxLabel : "100"),
        React.createElement(ReactSlider, { className: "vertical-slider", markClassName: "example-mark", thumbClassName: "thumb", trackClassName: "image-control-track", renderThumb: function (props, state) { return React.createElement("div", __assign({}, props), "\u00A0"); }, orientation: "vertical", invert: true, min: min, max: max, value: val, onChange: function (v) {
                setVal(v);
                onChange && onChange(v);
            }, minDistance: 0 }),
        React.createElement("span", { className: "legend mt-1 mb-3" }, minLabel !== null && minLabel !== void 0 ? minLabel : "0"),
        React.createElement(Icon, { name: "Reset", onClick: function () {
                setVal(defaultValue);
                onChange && onChange(defaultValue);
            } })));
};
var PanControl = function (_a) {
    var onChange = _a.onChange, onReset = _a.onReset;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    return show ? (React.createElement("div", { className: "space-y-2" },
        React.createElement(Icon, { name: "Hand", active: show, onClick: function () {
                setShow(false);
                onChange && onChange(false);
            } }),
        React.createElement(Icon, { name: "Reset", onClick: function () {
                onReset && onReset();
            } }))) : (React.createElement(Icon, { name: "Hand", onClick: function () {
            setShow(true);
            onChange && onChange(true);
        } }));
};
var PairImagesControl = function (_a) {
    var filters = _a.filters, setFilters = _a.setFilters, canvasConfig = _a.canvasConfig, setCanvasConfig = _a.setCanvasConfig;
    var handleFilter = function (key, val) {
        var _a;
        setFilters(__assign(__assign({}, filters), (_a = {}, _a[key] = __assign(__assign({}, filters[key]), { current: val }), _a)));
    };
    return (React.createElement("div", { className: "relative h-80" },
        React.createElement("div", { className: "space-y-3 bg-blue-1000 px-0 py-0 rounded-full" },
            React.createElement(SliderControl, { name: "Brightness", onChange: function (val) { return handleFilter("brightness", val, filters, setFilters); }, min: filters.brightness.min, max: filters.brightness.max, value: filters.brightness.current, defaultValue: filters.brightness.default }),
            React.createElement(SliderControl, { name: "Contrast", onChange: function (val) { return handleFilter("contrast", val, filters, setFilters); }, min: filters.contrast.min, max: filters.contrast.max, value: filters.contrast.current, defaultValue: filters.contrast.default }),
            React.createElement(SliderControl, { name: "Search", minLabel: "-", maxLabel: "+", min: filters.zoom.min, max: filters.zoom.max, value: filters.zoom.current, defaultValue: filters.zoom.default, onChange: function (val) { return handleFilter("zoom", val, filters, setFilters); } }),
            React.createElement(PanControl, { onReset: function () {
                    setCanvasConfig(__assign(__assign({}, canvasConfig), { image: {
                            x: 0,
                            y: 0,
                        }, groupRef: {
                            x: 0,
                            y: 0,
                        } }));
                }, onChange: function (val) {
                    setCanvasConfig(__assign(__assign({}, canvasConfig), { draggable: val }));
                } }))));
};
var ImagesPair = function (_a) {
    var currentSequenceId = _a.currentSequenceId, canvasConfig = _a.canvasConfig, setCanvasConfig = _a.setCanvasConfig, setSegmentationChanged = _a.setSegmentationChanged, placeCircleEnabled = _a.placeCircleEnabled, currentSequenceData = _a.currentSequenceData, vesselConfig = _a.vesselConfig, setVesselConfig = _a.setVesselConfig, seriesAssocation = _a.seriesAssocation, setSeriesAssociation = _a.setSeriesAssociation, segmentation = _a.segmentation, setSegmentation = _a.setSegmentation, overrideSegmentation = _a.overrideSegmentation, setOverrideSegmentation = _a.setOverrideSegmentation, currentIndex = _a.currentIndex, setCurrentIndex = _a.setCurrentIndex, workflow = _a.workflow, setWorkflow = _a.setWorkflow, setRefreshImages = _a.setRefreshImages, filters = _a.filters, setFilters = _a.setFilters, clear = _a.clear;
    var provider = useSquence();
    var getSequenceImages = provider.getSequenceImages, vesselsData = provider.vesselsData;
    var apiClient = useContext(ApiClientContext);
    var workflowId = useParams().id;
    var mriLabel = currentSequenceData.mriLabel, numStep = currentSequenceData.numStep;
    var _b = useState({
        phase: [],
        magnitude: [],
    }), images = _b[0], setImages = _b[1];
    useEffect(function () {
        if ("magnitude" in currentSequenceData && "phase" in currentSequenceData) {
            getSequenceImages(currentSequenceData).then(function (resp) {
                setImages(resp);
            })
                .catch(function (error) {
                console.log(error);
                setRefreshImages(true);
            });
        }
    }, [currentSequenceData]);
    var proccessedVessel = vesselConfig.proccessed !== null
        ? vesselsData[vesselConfig.proccessed]
        : null;
    var proccessedVesselColor = proccessedVessel
        ? proccessedVessel.color
        : "#00FFF0";
    return (React.createElement("div", { className: "bg-blue-1000" },
        React.createElement("div", { className: "flex flex-row justify-between px-4  py-3 text-xs uppercase" },
            React.createElement("div", { className: "text-azure-500 font-bold" }, "magnitude"),
            React.createElement("div", { className: "font-normal text-white" },
                mriLabel,
                " ",
                React.createElement("span", { className: "text-azure-500" },
                    "[",
                    currentIndex + 1,
                    "/",
                    numStep,
                    "]")),
            React.createElement("div", { className: "text-azure-500 font-bold" }, "phase")),
        React.createElement("div", { className: "pair-image-view relative" },
            React.createElement("div", { className: "canvas-wrapper" },
                images.magnitude.length && (React.createElement(ImageCanvas, { currentSequenceId: currentSequenceId, canvasConfig: canvasConfig, setCanvasConfig: setCanvasConfig, setSegmentationChanged: setSegmentationChanged, placeCircleEnabled: placeCircleEnabled, currentSequenceData: currentSequenceData, setSeriesAssociation: setSeriesAssociation, images: images.magnitude, index: currentIndex, proccessedVesselColor: proccessedVesselColor, segmentation: segmentation, overrideSegmentation: overrideSegmentation, setOverrideSegmentation: setOverrideSegmentation, filters: filters, key: "mag-canvas" })),
                images.phase.length && (React.createElement(ImageCanvas, { currentSequenceId: currentSequenceId, canvasConfig: canvasConfig, setCanvasConfig: setCanvasConfig, setSegmentationChanged: setSegmentationChanged, placeCircleEnabled: placeCircleEnabled, currentSequenceData: currentSequenceData, setSeriesAssociation: setSeriesAssociation, images: images.phase, index: currentIndex, proccessedVesselColor: proccessedVesselColor, segmentation: segmentation, overrideSegmentation: overrideSegmentation, setOverrideSegmentation: setOverrideSegmentation, filters: filters, key: "phase-canvas" }))),
            React.createElement("div", { className: "pair-controls" },
                React.createElement(PairImagesControl, { filters: filters, setFilters: setFilters, canvasConfig: canvasConfig, setCanvasConfig: setCanvasConfig }))),
        React.createElement("div", { className: "px-4 py-1 flex flex-row justify-between items-center" },
            React.createElement("span", { className: "w-16" }, "\u00A0"),
            React.createElement(VideoControls, { currentSequenceData: currentSequenceData, currentIndex: currentIndex, setCurrentIndex: setCurrentIndex }),
            React.createElement("span", { className: "w-16" },
                React.createElement("button", { className: "btn px-3 w-full ", type: "button", onClick: function () { return clear(); } }, "Clear")))));
};
export default ImagesPair;
