var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useState } from "react";
import ImagesPair from "./components/ImagesPair";
import Series from "./components/Series";
import { SequenceProvider } from "./utils/SequenceProvider";
import { CircularProgress, Typography, Stack } from '@mui/material';
import { ApiClientContext } from "common/api";
import VtfAnalysisChart from "./components/VtfAnalysisChart";
import "./index.css";
import { useParams } from "react-router-dom";
function round(n, precision) {
    if (precision < 0) {
        var length_1 = String(n).split('.')[0].length;
        return parseFloat(n.toLocaleString('en', { maximumSignificantDigits: length_1 + precision, useGrouping: false }));
    }
    return parseFloat(n.toLocaleString('en', { maximumFractionDigits: precision, useGrouping: false }));
}
function translateSegmentations(segmentation, canvasConfig, filters) {
    if (segmentation == null) {
        return [];
    }
    var leng = -1;
    var tmp = segmentation.reduce(function (prev, coord, index) {
        if (index % 2 === 0) {
            leng += 1;
            prev.push([coord]);
            return __spreadArray([], prev, true);
        }
        else {
            prev[leng].push(coord);
            return __spreadArray([], prev, true);
        }
    }, []);
    leng = -1;
    var points = tmp.map(function (xy) {
        var x = xy[0], y = xy[1];
        var newx = (round((round(x, 6) / 256), 6) * 580);
        var newy = (round((round(y, 6) / 256), 6) * 580);
        return [newx, newy];
    });
    return points.reduce(function (prev, point, index) {
        if (index % 6 === 0) {
            leng += 1;
            prev.push([point]);
        }
        else {
            prev[leng].push(point);
        }
        return __spreadArray([], prev, true);
    }, []);
}
// TODO review the data update with the provider
function App() {
    var _a = useState(null), workflow = _a[0], setWorkflow = _a[1];
    var _b = useState({
        draggable: false,
        groupRef: {
            x: 0,
            y: 0,
        },
        image: {
            x: 0,
            y: 0,
        },
        circle: {
            x: 0,
            y: 0,
            radius: 23,
            visible: false,
            placed: false,
            resized: false,
        },
    }), canvasConfig = _b[0], setCanvasConfig = _b[1];
    var _c = useState(false), segmentationChanged = _c[0], setSegmentationChanged = _c[1];
    var _d = useState(false), placeCircleEnabled = _d[0], setPlaceCircleEnabled = _d[1];
    var _e = useState({
        mriLabel: "",
        numStep: ""
    }), currentSequenceData = _e[0], setCurrentSequenceData = _e[1];
    var _f = useState(null), currentSequenceId = _f[0], setCurrentSequenceId = _f[1];
    var _g = useState({
        selected: null,
        proccessed: null,
        completed: null,
    }), vesselConfig = _g[0], setVesselConfig = _g[1];
    var _h = useState([]), seriesAssociation = _h[0], setSeriesAssociation = _h[1];
    var _j = useState([]), segmentation = _j[0], setSegmentation = _j[1];
    var _k = useState({}), overrideSegmentation = _k[0], setOverrideSegmentation = _k[1];
    var _l = useState(0), currentIndex = _l[0], setCurrentIndex = _l[1];
    var _m = useState(null), data = _m[0], setData = _m[1];
    var _o = useState(null), metrics = _o[0], setMetrics = _o[1];
    var _p = useState(false), refreshImages = _p[0], setRefreshImages = _p[1];
    var vesselsData = [
        {
            name: "LT-PROX",
            className: "vessel-1",
            color: "#F38C8C",
        },
        {
            name: "LT-DIST",
            className: "vessel-2",
            color: "#98DFE2",
        },
        {
            name: "RT-PROX",
            className: "vessel-3",
            color: "#F7AB7E",
        },
        {
            name: "RT-DIST",
            className: "vessel-4",
            color: "#807EF7",
        },
        {
            name: "MAIN-PA",
            className: "vessel-5",
            color: "#EC5141",
        },
        {
            name: "MAIN-Ao",
            className: "vessel-6",
            color: "#7CABE6",
        },
        {
            name: "ASC-Ao",
            className: "vessel-7",
            color: "#60db71",
        },
        {
            name: "DSC-Ao",
            className: "vessel-8",
            color: "#e3e162",
        },
        {
            name: "Other-1",
            className: "vessel-9",
            color: "#62e3b6",
        },
        {
            name: "Other-2",
            className: "vessel-10",
            color: "#c0ed74",
        },
    ];
    var _q = useState({
        brightness: {
            current: 0,
            default: 0,
            min: -25,
            max: 25,
        },
        contrast: {
            current: 0,
            default: 0,
            min: -25,
            max: 25,
        },
        zoom: {
            current: 50,
            default: 50,
            min: 50,
            max: 100,
        },
    }), filters = _q[0], setFilters = _q[1];
    var apiClient = useContext(ApiClientContext);
    var id = useParams().id;
    var _r = useState(false), key = _r[0], setKey = _r[1];
    var _s = useState(false), loading = _s[0], setLoading = _s[1];
    useEffect(function () {
        apiClient.getWorkflow(id).then(function (response) {
            var _a;
            var promiseChain = ((_a = response === null || response === void 0 ? void 0 : response.segmentations) !== null && _a !== void 0 ? _a : []).map(function (segId) {
                setLoading(true);
                return apiClient.getSegmentation(id, segId);
            }, Promise.resolve());
            Promise.all(promiseChain).then(function (results) {
                var _a;
                var workflow = __assign(__assign({}, response), { segmentations: results });
                setWorkflow(workflow);
                var currentSequenceId = workflow.sequences[0];
                setCurrentSequenceId(currentSequenceId);
                var currentSeg = workflow.segmentations.find(function (seg) { return seg.sequence == currentSequenceId; });
                var segLocation = (_a = currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.location) !== null && _a !== void 0 ? _a : "";
                var vesselIdx = vesselsData.findIndex(function (vessel) { return vessel.name === segLocation; });
                setVesselConfig({
                    proccessed: vesselIdx != -1 ? vesselIdx : null,
                    selected: vesselIdx != -1 ? "".concat(vesselIdx) : null,
                    completed: currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.complete,
                });
                results.length > 0 && setSegmentation(translateSegmentations(currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.points, canvasConfig, filters));
                setLoading(false);
            });
        });
    }, []);
    useEffect(function () {
        var _a, _b;
        if (workflow && ((_a = workflow === null || workflow === void 0 ? void 0 : workflow.segmentations) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var currentSeg_1 = workflow.segmentations.find(function (seg) { return seg.sequence == currentSequenceId; });
            setSegmentation(translateSegmentations(currentSeg_1 === null || currentSeg_1 === void 0 ? void 0 : currentSeg_1.points, canvasConfig, filters));
            setSeriesAssociation(function (prev) {
                return prev.map(function (existingAssociations) {
                    var _a;
                    return existingAssociations.associatedSeries.mriLabel ===
                        currentSequenceData.mriLabel
                        ? __assign(__assign({}, existingAssociations), { segmentation: (_a = translateSegmentations(currentSeg_1 === null || currentSeg_1 === void 0 ? void 0 : currentSeg_1.points, canvasConfig, filters)) !== null && _a !== void 0 ? _a : [], completed: (currentSeg_1 === null || currentSeg_1 === void 0 ? void 0 : currentSeg_1.complete) && Object.keys(overrideSegmentation[currentSequenceId]).length == 0 }) : __assign({}, existingAssociations);
                });
            });
            document
                .querySelectorAll("input[name=type-box]")
                .forEach(function (e) { return (e.checked = false); });
            var segLocation_1 = (_b = currentSeg_1 === null || currentSeg_1 === void 0 ? void 0 : currentSeg_1.location) !== null && _b !== void 0 ? _b : "";
            var vesselIdx = vesselsData.findIndex(function (vessel) { return vessel.name === segLocation_1; });
            setVesselConfig({
                proccessed: vesselIdx != -1 ? vesselIdx : null,
                selected: vesselIdx != -1 ? "".concat(vesselIdx) : null,
                completed: (currentSeg_1 === null || currentSeg_1 === void 0 ? void 0 : currentSeg_1.complete) && Object.keys(overrideSegmentation[currentSequenceId]).length == 0,
            });
            setSegmentationChanged(JSON.stringify(overrideSegmentation[currentSequenceId]) !== "{}");
        }
        else {
            setSegmentation([]);
        }
    }, [currentSequenceId]);
    useEffect(function () {
        setRefreshImages(true);
    }, []);
    useEffect(function () {
        if (refreshImages) {
            apiClient.getDicomZip(id).then(function (response) {
                setData(response);
                var keys = Object.keys(response);
                setKey(keys[0]);
                var responseSequences = response[keys[0]].sequences;
                var sequenceKey = Object.keys(responseSequences[0]);
                setCurrentSequenceData(responseSequences[0][sequenceKey]);
                setRefreshImages(false);
            });
        }
    }, [refreshImages]);
    // set associations on initial render
    useEffect(function () {
        var _a;
        if (data && workflow) {
            workflow.sequences.forEach(function (seq) {
                if (!(seq in overrideSegmentation)) {
                    overrideSegmentation[seq] = {};
                }
            });
            setOverrideSegmentation(__assign({}, overrideSegmentation));
            var seriesAss_1 = [];
            workflow.segmentations.forEach(function (seg) {
                var _a;
                var sequences = data[Object.keys(data)[0]].sequences;
                var sequence = sequences.find(function (seq) { return Object.keys(seq)[0] == seg.sequence; });
                var sequenceId = Object.keys(sequence)[0];
                sequence = sequence[sequenceId];
                sequence = __assign({ "id": sequenceId }, sequence);
                var vessel = vesselsData.find(function (vessel) { return vessel.name == seg.location; });
                var segment = (_a = translateSegmentations(seg === null || seg === void 0 ? void 0 : seg.points, canvasConfig, filters)) !== null && _a !== void 0 ? _a : [];
                if (sequence && vessel && segment) {
                    var newAssociation = {
                        "associatedSeries": sequence,
                        "associatedTo": vessel,
                        "segmentation": segment,
                        "completed": (seg === null || seg === void 0 ? void 0 : seg.complete) && Object.keys(overrideSegmentation[sequenceId]).length == 0
                    };
                    seriesAss_1.push(newAssociation);
                }
            });
            setCanvasConfig(__assign(__assign({}, canvasConfig), { circle: __assign(__assign({}, canvasConfig.circle), { placed: false, visible: false }) }));
            var currentSeg = workflow.segmentations.find(function (seg) { return seg.sequence == currentSequenceId; });
            var segLocation_2 = (_a = currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.location) !== null && _a !== void 0 ? _a : "";
            var vesselIdx = vesselsData.findIndex(function (vessel) { return vessel.name === segLocation_2; });
            setVesselConfig({
                proccessed: vesselIdx != -1 ? vesselIdx : null,
                selected: vesselIdx != -1 ? "".concat(vesselIdx) : null,
                completed: (currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.complete) && Object.keys(overrideSegmentation[currentSequenceId]).length == 0,
            });
            setSegmentation(translateSegmentations(currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.points, canvasConfig, filters));
            setSeriesAssociation(seriesAss_1);
            apiClient.getReportData(id).then(function (response) {
                //console.log(response)
                var newMetrics = {};
                var sequences = data[Object.keys(data)[0]].sequences;
                sequences.forEach(function (seq) {
                    var currSeqId = Object.keys(seq)[0];
                    var currMriLabel = seq[currSeqId].mriLabel;
                    var metricsKeys = Object.keys(response);
                    //console.log(metricsKeys)
                    var foundRelatedSequence = metricsKeys.includes(currMriLabel);
                    if (foundRelatedSequence) {
                        newMetrics[currSeqId] = response[currMriLabel];
                    }
                    else {
                        newMetrics[currSeqId] = {};
                    }
                });
                setMetrics(__assign({}, newMetrics));
            });
        }
    }, [workflow]);
    useEffect(function () {
        if (JSON.stringify(overrideSegmentation) !== "{}") {
            Object.keys(overrideSegmentation).forEach(function (seqKey) {
                var association = seriesAssociation.find(function (association) { return association.associatedSeries.id == seqKey; });
                var currentSeg = workflow.segmentations.find(function (seg) { return seg.sequence == seqKey; });
                if (association) {
                    association.completed = (currentSeg === null || currentSeg === void 0 ? void 0 : currentSeg.complete) && Object.keys(overrideSegmentation[seqKey]).length == 0;
                }
            });
            setSeriesAssociation(seriesAssociation);
        }
    }, [overrideSegmentation]);
    var clear = function () {
        var _a;
        var segId = (_a = workflow.segmentations.find(function (seg) { return seg.sequence === currentSequenceId; })) === null || _a === void 0 ? void 0 : _a.id;
        if (segId) {
            apiClient.deleteSegmentation(id, segId).then(function (res) {
                var segmentations = workflow.segmentations;
                var removeIdx = segmentations.findIndex(function (seg) { return seg.sequence == currentSequenceId; });
                segmentations.splice(removeIdx, 1);
                setWorkflow(__assign(__assign({}, workflow), { segmentations: segmentations }));
            });
        }
        else {
            setVesselConfig({
                proccessed: null,
                selected: null,
                complete: null
            });
            setSegmentation([]);
            setCurrentIndex(0);
            setCanvasConfig(__assign(__assign({}, canvasConfig), { circle: __assign(__assign({}, canvasConfig.circle), { placed: false, visible: false }) }));
        }
    };
    var proccessedVessel = vesselConfig.proccessed !== null
        ? vesselsData[vesselConfig.proccessed]
        : null;
    var proccessedVesselColor = proccessedVessel
        ? proccessedVessel.color
        : "#00FFF0";
    if (loading) {
        return (React.createElement(CircularProgress, null));
    }
    return (data &&
        key && (React.createElement(SequenceProvider, null,
        React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: "w-1160 flex border-gray-500 border", style: { width: "max-content" } },
                React.createElement("div", { className: "main-container border-r border-gray-500 w-full" },
                    React.createElement("div", { className: "w-full border-b  border-gray-500" },
                        React.createElement(ImagesPair, { filters: filters, setFilters: setFilters, currentSequenceId: currentSequenceId, canvasConfig: canvasConfig, setCanvasConfig: setCanvasConfig, segmentationChanged: segmentationChanged, setSegmentationChanged: setSegmentationChanged, placeCircleEnabled: placeCircleEnabled, currentSequenceData: currentSequenceData, vesselConfig: vesselConfig, setVesselConfig: setVesselConfig, seriesAssociation: seriesAssociation, setSeriesAssociation: setSeriesAssociation, segmentation: segmentation, setSegmentation: setSegmentation, overrideSegmentation: overrideSegmentation, setOverrideSegmentation: setOverrideSegmentation, currentIndex: currentIndex, setCurrentIndex: setCurrentIndex, workflow: workflow, setWorkflow: setWorkflow, clear: clear, setRefreshImages: setRefreshImages })),
                    React.createElement("div", { className: "h-72" },
                        React.createElement(Series, { data: data[key].sequences, workflow: workflow, setWorkflow: setWorkflow, canvasConfig: canvasConfig, setCanvasConfig: setCanvasConfig, segmentationChanged: segmentationChanged, setSegmentationChanged: setSegmentationChanged, setPlaceCircleEnabled: setPlaceCircleEnabled, currentSequenceData: currentSequenceData, setCurrentSequenceData: setCurrentSequenceData, currentSequenceId: currentSequenceId, setCurrentSequenceId: setCurrentSequenceId, vesselConfig: vesselConfig, setVesselConfig: setVesselConfig, seriesAssociation: seriesAssociation, setSeriesAssociation: setSeriesAssociation, segmentation: segmentation, setSegmentation: setSegmentation, overrideSegmentation: overrideSegmentation, setOverrideSegmentation: setOverrideSegmentation, currentIndex: currentIndex, setCurrentIndex: setCurrentIndex, clear: clear, filters: filters })))),
            React.createElement("div", { className: "border-gray-500 border-r fill-content ml-5" },
                React.createElement(Typography, { variant: "h5", className: "text-azure-1000 font-medium text-sm" }, "Statistics Overview"),
                metrics && Object.keys(metrics[currentSequenceId]).length > 0 &&
                    React.createElement("div", null,
                        React.createElement(VtfAnalysisChart, { title: "Velocities vs Time", xAxisLabel: "ms", yAxisLabel: "cm/sec", xData: metrics[currentSequenceId].t, yDataSets: [metrics[currentSequenceId].v[0]], lineColor: proccessedVesselColor }),
                        React.createElement(VtfAnalysisChart, { title: "Area vs Time", xAxisLabel: "ms", yAxisLabel: "cm^2", xData: metrics[currentSequenceId].t, yDataSets: [metrics[currentSequenceId].v[1]], lineColor: proccessedVesselColor }),
                        React.createElement(Stack, null,
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Backward Flow:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].BackwardFlow.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].BackwardFlow.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Forward Flow:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].ForwardFlow.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].ForwardFlow.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Max Area:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].MaxArea.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].MaxArea.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Max Asc Aorta Diameter:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].MaxAscAortaDiameter.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].MaxAscAortaDiameter.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Max Diameter:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].MaxDiameter.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].MaxDiameter.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Min Area:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].MinArea.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].MinArea.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "PA/Ao Ratio:"),
                                " ",
                                metrics[currentSequenceId]["PA/Ao Ratio"].value,
                                " ",
                                metrics[currentSequenceId]["PA/Ao Ratio"].label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Pulsatility:"),
                                " ",
                                metrics[currentSequenceId].Pulsatility.label,
                                parseFloat(metrics[currentSequenceId].Pulsatility.value).toFixed(2)),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Pulse Wave Velocity:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].PulseWaveVelocity.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].PulseWaveVelocity.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Pump Blood Volume:"),
                                " ",
                                parseFloat(metrics[currentSequenceId].PumpBloodVolume.value).toFixed(2),
                                " ",
                                metrics[currentSequenceId].PumpBloodVolume.label),
                            React.createElement(Typography, { variant: "subtitle1" },
                                React.createElement("b", null, "Vtf Pa Stiffness Index:"),
                                " ",
                                metrics[currentSequenceId].VtfPaStiffnessIndex.value,
                                " ",
                                metrics[currentSequenceId].VtfPaStiffnessIndex.label))),
                metrics && Object.keys(metrics[currentSequenceId]).length === 0 &&
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "subtitle1" }, "No Metrics available")))))));
}
export default App;
