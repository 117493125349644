import React from "react";
import Home from 'views/Home';
import About from 'views/About';
import SubmitRequest from 'views/SubmitRequest';
import SegAnalysisLanding from 'views/SegAnalysisLanding';
import SegAnalysis from 'views/SegAnalysis';
import ReqDetail from 'views/ReqDetail';
import SubmitIssue from 'views/SubmitIssue';
var routes = [
    {
        name: "home",
        path: "/",
        inDrawer: false,
        element: React.createElement(Home, null)
    },
    {
        name: "about",
        path: "/about",
        inDrawer: true,
        element: React.createElement(About, null)
    },
    {
        name: "submit request",
        path: "/request",
        inDrawer: true,
        restricted: ['Admin', 'Staff', 'Doctor'],
        element: React.createElement(SubmitRequest, null)
    },
    {
        name: "request detail",
        path: "/request/:id",
        inDrawer: false,
        restricted: ['Admin', 'Staff', 'Doctor'],
        element: React.createElement(ReqDetail, null)
    },
    {
        name: "perform analysis",
        path: "/analysis",
        inDrawer: true,
        restricted: ['Admin', 'Technician'],
        element: React.createElement(SegAnalysisLanding, null)
    },
    {
        name: "segmentation tool",
        path: "/analysis/:id",
        restricted: ['Admin', 'Technician'],
        element: React.createElement(SegAnalysis, null)
    },
    {
        name: "user accounts",
        path: "/user_accounts",
        restricted: ['Admin', 'Supervisor'],
        element: null
    },
    {
        name: "user profile",
        path: "/profile",
        restricted: [],
        element: null
    },
    {
        name: "report issue",
        path: "/report_issue",
        inDrawer: true,
        restricted: ['Admin', 'Doctor', 'Staff', 'Supervisor', 'Technician'],
        element: React.createElement(SubmitIssue, null)
    },
    {
        name: "quality check",
        path: "/quality_check",
        restricted: ['Admin'],
        element: null
    },
    {
        name: "system audit",
        path: "/system_audit",
        restricted: ['Admin'],
        element: null
    },
    {
        name: "404",
        path: "/*",
        element: null
    }
];
export default routes;
