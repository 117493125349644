var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSquence } from "../utils/SequenceProvider";
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import { ApiClientContext } from "common/api";
import { useParams } from "react-router-dom";
import { CircularProgress } from '@mui/material';
var SeriesType = function (_a) {
    var type = _a.type, index = _a.index, onChange = _a.onChange, active = _a.active, associateVessel = _a.associateVessel, hasSeries = _a.hasSeries, disAssociateVessel = _a.disAssociateVessel, isImageSeriesLabelled = _a.isImageSeriesLabelled, isLabelCurrentlyUsed = _a.isLabelCurrentlyUsed, overrideLabel = _a.overrideLabel, reLabelSeries = _a.reLabelSeries, completed = _a.completed, onClick = _a.onClick, checked = _a.checked, currentSequenceData = _a.currentSequenceData, seriesAssociation = _a.seriesAssociation, currentSequenceId = _a.currentSequenceId;
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var name = type.name, style = type.className;
    var associated = isLabelCurrentlyUsed(name, seriesAssociation);
    var divRef = useRef();
    var contextMenuClick = function (e) {
        if (divRef.current && divRef.current.contains(e.target)) {
            e.preventDefault();
            setMenuOpen(true);
        }
    };
    useEffect(function () {
        if (divRef.current) {
            document.addEventListener("contextmenu", contextMenuClick);
        }
        return function () {
            document.removeEventListener("contextmenu", contextMenuClick);
        };
    }, [divRef]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: divRef, className: "label-type ".concat(style, " ").concat(active ? "active" : "", " ").concat(associated ? "associated" : "", " "), onClick: onClick },
            React.createElement("input", { type: "radio", id: "radio-".concat(index), name: "type-box", value: index, onChange: onChange, checked: checked }),
            React.createElement("label", { htmlFor: "radio-".concat(index) },
                associated ? (React.createElement(React.Fragment, null, completed ? (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "icon-sc-Circle-check" }, "\u00A0"))) : (React.createElement("span", { className: "ml-0.5" },
                    React.createElement("i", { className: "circle" }))))) : (React.createElement("span", { className: "w-3 h-3 block rounded-full" })),
                React.createElement("span", null, name)),
            React.createElement(ControlledMenu, { state: menuOpen ? "open" : "closed", anchorRef: divRef, onMouseLeave: function () { return setMenuOpen(false); }, onClose: function () { return setMenuOpen(false); }, menuClassName: "vesselMenu" }, !isImageSeriesLabelled(currentSequenceData, seriesAssociation) && isLabelCurrentlyUsed(name, seriesAssociation) ? (React.createElement(MenuItem, { onClick: overrideLabel }, "Override label")) : !isImageSeriesLabelled(currentSequenceData, seriesAssociation) ? (React.createElement(MenuItem, { onClick: associateVessel }, "Apply label")) : isLabelCurrentlyUsed(name, seriesAssociation) ? (React.createElement(MenuItem, { onClick: disAssociateVessel }, "Un Apply label")) : (React.createElement(MenuItem, { onClick: reLabelSeries }, "Re label"))))));
};
var Sequence = function (_a) {
    var workflow = _a.workflow, setWorkflow = _a.setWorkflow, canvasConfig = _a.canvasConfig, setCanvasConfig = _a.setCanvasConfig, segmentationChanged = _a.segmentationChanged, setSegmentationChanged = _a.setSegmentationChanged, setPlaceCircleEnabled = _a.setPlaceCircleEnabled, currentSequenceData = _a.currentSequenceData, currentSequenceId = _a.currentSequenceId, vesselConfig = _a.vesselConfig, setVesselConfig = _a.setVesselConfig, seriesAssociation = _a.seriesAssociation, setSeriesAssociation = _a.setSeriesAssociation, segmentation = _a.segmentation, setSegmentation = _a.setSegmentation, overrideSegmentation = _a.overrideSegmentation, setOverrideSegmentation = _a.setOverrideSegmentation, currentIndex = _a.currentIndex, setCurrentIndex = _a.setCurrentIndex, clear = _a.clear, filters = _a.filters, handleTypeChange = _a.handleTypeChange, handleSeriesChange = _a.handleSeriesChange, highlightVessel = _a.highlightVessel, selected = _a.selected, setSelected = _a.setSelected;
    var _b = useState(0), offset = _b[0], setOffset = _b[1];
    var provider = useSquence();
    var _c = useState(), updateState = _c[1];
    var forceUpdate = useCallback(function () { return updateState({}); }, []);
    var isVesselCompleted = provider.isVesselCompleted, vesselsData = provider.vesselsData, isImageSeriesLabelled = provider.isImageSeriesLabelled, isLabelCurrentlyUsed = provider.isLabelCurrentlyUsed, associatedVesselIndex = provider.associatedVesselIndex, isVesselProcessed = provider.isVesselProcessed, findAssociationBySeries = provider.findAssociationBySeries;
    var apiClient = useContext(ApiClientContext);
    var workflowId = useParams().id;
    var _d = useState(false), runSegLoading = _d[0], setRunSegLoading = _d[1];
    var _e = useState(false), completeLoading = _e[0], setCompleteLoading = _e[1];
    useEffect(function () {
        if (currentIndex === null) {
            setSelected([]);
        }
    }, [currentIndex]);
    var handleAssociateVessel = function (type, index) {
        setSeriesAssociation(function (prev) {
            return __spreadArray(__spreadArray([], prev, true), [
                {
                    associatedSeries: __assign(__assign({}, currentSequenceData), { id: currentSequenceId }),
                    associatedTo: __assign({}, type),
                    segmentation: segmentation ? segmentation : [],
                },
            ], false);
        });
        setVesselConfig(function (prev) {
            return {
                proccessed: prev.proccessed === null ? null : parseInt(index),
                selected: index.toString(),
                completed: prev.completed === null ? null : prev.completed,
            };
        });
        handleTypeChange(index);
    };
    var handleDisAssociateVessel = function (type) {
        clear();
    };
    //re-label already associated vessel to series
    var reLabelSeries = function (type, index) {
        setSeriesAssociation(function (prev) {
            return prev.filter(function (association) {
                return association.associatedSeries.mriLabel !== currentSequenceData.mriLabel;
            });
        });
        setVesselConfig(function (prev) { return ({
            proccessed: prev.proccessed === null ? null : prev.proccessed,
            selected: null,
            completed: prev.completed === null ? null : prev.completed,
        }); });
        handleAssociateVessel(type, index);
    };
    //override associated vessel to new series
    var overrideLabel = function (type, index) {
        handleDisAssociateVessel(type);
        handleAssociateVessel(type, index);
    };
    var handleSegmentationDD = function (ev, key) {
        var _a;
        ev.preventDefault();
        var _b = canvasConfig.image, imgX = _b.x, imgY = _b.y;
        var ratio = filters.zoom.current / 50;
        var circleScaledX = (canvasConfig.circle.x - imgX) / ratio / 580 * 256;
        var circleScaledY = (canvasConfig.circle.y - imgY) / ratio / 580 * 256;
        var scaledRadius = canvasConfig.circle.radius / ratio / 580 * 256;
        // Send more data
        var segmentationData = {
            params: {
                // Adding the Vessel
                // OUT OF SCOPE!!
                // step: 0,
                // x,y, r from drawned circle
                x: circleScaledX,
                y: circleScaledY,
                radius: scaledRadius,
                // define viewpoint
                viewDim: 256
            },
        };
        // After a seed circle is placed, we can generate an initial segmentation based on
        // the x,y coordinates and a radius
        if (key === "run") {
            setRunSegLoading(true);
            var association = seriesAssociation.find(function (seriesAss) { return seriesAss.associatedSeries.mriLabel === currentSequenceData.mriLabel; });
            var newSegmentationLocation = association.associatedTo.name;
            // We have to translate the points to proper coordinate system  
            apiClient.createSegmentation(workflowId, currentSequenceId, newSegmentationLocation, segmentationData.params).then(function (res) {
                var groupRefX = canvasConfig.image.x;
                var groupRefY = canvasConfig.image.y;
                setCanvasConfig(__assign(__assign({}, canvasConfig), { groupRef: {
                        x: groupRefX,
                        y: groupRefY
                    } }));
                var segmentations = workflow.segmentations;
                segmentations.push(res);
                setWorkflow(__assign(__assign({}, workflow), { segmentations: segmentations }));
                setRunSegLoading(false);
            });
        }
        // After and initial segmentation, the user may alter the results manually. This button click
        // will save the changes
        if (key === "process") {
            setCompleteLoading(true);
            var association = seriesAssociation.find(function (seriesAss) { return seriesAss.associatedSeries.mriLabel === currentSequenceData.mriLabel; });
            var newSegmentationLocation = association.associatedTo.name;
            var segId = (_a = workflow.segmentations.find(function (seg) { return seg.sequence === currentSequenceId; })) === null || _a === void 0 ? void 0 : _a.id;
            var currOverrideSegmentation_1 = overrideSegmentation[currentSequenceId];
            var translatedOverrideSeg = Object.keys(currOverrideSegmentation_1).reduce(function (aggr, stepSeg) {
                var _a;
                return __assign(__assign({}, aggr), (_a = {}, _a[stepSeg] = currOverrideSegmentation_1[stepSeg].map(function (xy) {
                    var x = Math.round((((xy[0]) / 580) * 256));
                    var y = Math.round((((xy[1]) / 580) * 256));
                    return [x, y];
                }), _a));
            }, {});
            apiClient.patchSegmentation(workflowId, segId, translatedOverrideSeg, newSegmentationLocation).then(function (res) {
                var currOverride = overrideSegmentation[currentSequenceId];
                var overrideKeys = Object.keys(currOverride);
                var currSegmentation = segmentation;
                overrideKeys.forEach(function (key) {
                    currSegmentation[key] = currOverride[key];
                });
                var currSegIdx = workflow.segmentations.findIndex(function (seg) { return seg.sequence == currentSequenceId; });
                currSegmentation = currSegmentation.reduce(function (prev, curr) {
                    var translated = curr.map(function (xy) {
                        var x = Math.round((xy[0] / 580) * 256);
                        var y = Math.round((xy[1] / 580) * 256);
                        return [x, y];
                    });
                    prev.push(translated.flat());
                    return prev;
                }, []);
                overrideSegmentation[currentSequenceId] = {};
                setOverrideSegmentation(__assign({}, overrideSegmentation));
                var association = findAssociationBySeries(currentSequenceData.mriLabel, seriesAssociation);
                var associatedSeries = association.associatedSeries;
                setSeriesAssociation(function (prev) {
                    return prev.map(function (prevAssociation) {
                        return prevAssociation.associatedSeries.id === associatedSeries.id
                            ? __assign(__assign({}, prevAssociation), { completed: true }) : __assign({}, prevAssociation);
                    });
                });
                association = findAssociationBySeries(currentSequenceData.mriLabel, seriesAssociation);
                workflow.segmentations[currSegIdx].location = association.associatedTo.name;
                workflow.segmentations[currSegIdx].points = currSegmentation.flat();
                workflow.segmentations[currSegIdx].complete = true;
                setWorkflow(__assign({}, workflow));
                setSegmentationChanged(JSON.stringify(overrideSegmentation[currentSequenceId]) !== "{}");
                setCompleteLoading(false);
            });
        }
    };
    var handleSquareChange = function (ev, index, checked) {
        var shiftKey = ev.nativeEvent.shiftKey;
        if (checked) {
            if (shiftKey) {
                if (index > selected[selected.length - 1]) {
                    setSelected(__spreadArray(__spreadArray([], selected, true), range(selected[selected.length - 1] + 1, index, 1), true));
                }
                else {
                    setSelected(__spreadArray([], range(index, selected[selected.length - 1] + 1, 1), true));
                }
            }
            else {
                setSelected([index]);
            }
            setCurrentIndex(index);
        }
        else {
            if (shiftKey) {
                // This becomes the newest top
                var newArray = range(selected[0], index, 1);
                setSelected(newArray);
                setCurrentIndex(index);
            }
            else {
                // Gets unselected
                var newArray = selected;
                for (var i = 0; i < newArray.length; i++) {
                    if (newArray[i] === index)
                        newArray.splice(i, 1);
                }
                setSelected(newArray);
            }
            forceUpdate();
        }
    };
    var navigate = useNavigate();
    var handleComplete = function (ev) {
        ev.preventDefault();
        setRunSegLoading(true);
        apiClient.generateReport(workflowId).then(function (res) {
            setRunSegLoading(false);
            if (res !== "Not Ready") {
                navigate("/analysis");
            }
        });
    };
    var numStep = currentSequenceData.numStep;
    var showPrevNext = numStep > 20;
    var maxOffset = numStep - 20;
    var playOffset = Math.max(currentIndex + 1 - 20, 0);
    var realOffset = Math.max(playOffset, offset);
    var overrideKeys = currentSequenceId in overrideSegmentation ? Object.keys(overrideSegmentation[currentSequenceId]).map(function (e) {
        return parseInt(e);
    }) : [];
    var proccessedVessel = vesselConfig.proccessed !== null
        ? vesselsData[vesselConfig.proccessed]
        : null;
    var proccessedVesselClass = proccessedVessel
        ? proccessedVessel.className
        : "";
    var appliedVessel = seriesAssociation.find(function (association) {
        return association.associatedSeries.mriLabel === currentSequenceData.mriLabel;
    });
    return (React.createElement("div", { className: "grid grid-cols-5" },
        React.createElement("div", { className: "col-span-4" },
            React.createElement("div", { className: "flex flex-row justify-between items-center" },
                React.createElement("div", { className: "flex space-x-2 mt-4" },
                    showPrevNext && (React.createElement("button", { className: "rotate-90 square-prev", onClick: function (ev) {
                            ev.preventDefault();
                            if (offset > 0)
                                setOffset(offset - 1);
                        } },
                        React.createElement("i", { className: "icon-sc-Expand transition-all " }))),
                    Array.from(Array(numStep).keys())
                        .slice(realOffset, realOffset + 20)
                        .map(function (img, index) {
                        return (React.createElement("div", { className: "square-checkbox-wrapper", key: "square-".concat(realOffset + index) },
                            React.createElement("input", { type: "checkbox", id: "square-".concat(realOffset + index), name: "image-square", value: index + realOffset, readOnly: true, checked: selected.includes(realOffset + index) }),
                            React.createElement("label", { htmlFor: "square-".concat(realOffset + index), className: "square \n                      ".concat(overrideKeys.includes(realOffset + index)
                                    ? "square-modified"
                                    : "", "\n                      ").concat(selected.includes(realOffset + index)
                                    ? "square-selected"
                                    : "", " ").concat(currentIndex === realOffset + index
                                    ? "square-in-view"
                                    : "", "  ").concat(segmentation.length === 0 ? "square-unproccessed" : ""), onClick: function (ev) {
                                    // Code commented out as default image is not set
                                    // if (segmentation.length === 0) {
                                    //   ev.preventDefault();
                                    //   return false;
                                    // }
                                    handleSquareChange(ev, index + realOffset, !selected.includes(index + realOffset));
                                } },
                                React.createElement("span", { className: "square-item text-xxs text-white text-center ".concat(appliedVessel && appliedVessel.associatedTo.className, " ").concat(proccessedVesselClass) }, index + realOffset + 1))));
                    }),
                    showPrevNext && (React.createElement("button", { className: "-rotate-90 square-next", onClick: function (ev) {
                            ev.preventDefault();
                            if (offset < maxOffset)
                                setOffset(offset + 1);
                        } },
                        React.createElement("i", { className: "icon-sc-Expand transition-all " }))))),
            React.createElement("div", { className: "flex flex-row justify-between items-center mt-4" },
                React.createElement("div", { className: "grid grid-cols-5 gap-x-2 gap-y-2" }, vesselsData.map(function (type, index) { return (React.createElement(SeriesType, { index: index, type: type, key: index, active: index === associatedVesselIndex(type.name, currentSequenceData, seriesAssociation) &&
                        isVesselProcessed(type.name, seriesAssociation)
                        ? true
                        : false, checked: index === highlightVessel, onChange: function () { return handleTypeChange(index); }, onClick: function (e) { return handleSeriesChange(e, type.name, index); }, hasSeries: seriesAssociation, isImageSeriesLabelled: function () { return isImageSeriesLabelled(currentSequenceData, seriesAssociation); }, isLabelCurrentlyUsed: isLabelCurrentlyUsed, associateVessel: function () { return handleAssociateVessel(type, index); }, disAssociateVessel: function () { return handleDisAssociateVessel(type, index); }, overrideLabel: function () { return overrideLabel(type, index); }, reLabelSeries: function () { return reLabelSeries(type, index); }, completed: isVesselCompleted(type.name, seriesAssociation), currentSequenceData: currentSequenceData, seriesAssociation: seriesAssociation, currentsequenceId: currentSequenceId })); })))),
        React.createElement("div", { className: "col-span-1" },
            React.createElement("div", { className: "flex flex-row justify-end items-center my-4" },
                runSegLoading &&
                    React.createElement("div", { className: "flex justify-center items-center" },
                        React.createElement(CircularProgress, { size: 20, thickness: 3, color: "secondary" })),
                React.createElement("button", { className: "cta w-full justify-between", disabled: !isImageSeriesLabelled(currentSequenceData, seriesAssociation)
                        || (segmentation === null || segmentation === void 0 ? void 0 : segmentation.length) > 0, onClick: function (ev) {
                        if ((segmentation === null || segmentation === void 0 ? void 0 : segmentation.length) === 0 && !canvasConfig.circle.placed) {
                            setPlaceCircleEnabled(true);
                        }
                        else if ((segmentation === null || segmentation === void 0 ? void 0 : segmentation.length) > 0) {
                            console.log("FUTURE LOGIC FOR MULTI SEGMENTATION");
                        }
                        else {
                            handleSegmentationDD(ev, "run");
                        }
                    } },
                    React.createElement("span", null, (segmentation === null || segmentation === void 0 ? void 0 : segmentation.length) === 0 && !canvasConfig.circle.placed
                        ? "Place Circle"
                        : "Run Segmentation"))),
            React.createElement("div", { className: "flex flex-row justify-end items-center" },
                completeLoading && React.createElement("div", { className: "flex justify-center items-center" },
                    React.createElement(CircularProgress, { size: 20, thickness: 3, color: "secondary" })),
                React.createElement("button", { className: "cta", disabled: segmentation.length === 0 || segmentationChanged === false, onClick: function (ev) { return handleSegmentationDD(ev, "process"); } },
                    React.createElement("span", null, "Save Analysis"))),
            React.createElement("div", { className: "flex flex-row justify-end items-center mt-4" },
                React.createElement("button", { className: "cta", disabled: workflow.segmentations.length !== 5 || segmentation.length === 0 || segmentationChanged === true, onClick: function (ev) { return handleComplete(ev); } },
                    React.createElement("span", null, "Complete"))))));
};
export default Sequence;
