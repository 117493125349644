import React from 'react';
import { Chip } from '@mui/material';
import { WorkflowStatus } from 'common/models';
var WorkflowStatusChip = function (props) {
    var color = props.status === WorkflowStatus.CANCELED ? 'info' :
        props.status === WorkflowStatus.UNKNOWN
            || props.status === WorkflowStatus.ERROR ? 'error' :
            props.status === WorkflowStatus.REPORT
                || props.status === WorkflowStatus.BILLED
                || props.status === WorkflowStatus.PAID ? 'success' : 'primary';
    return (React.createElement(Chip, { label: props.status, color: color }));
};
export default WorkflowStatusChip;
