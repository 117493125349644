var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState } from 'react';
export function useApiCall(apiCall, params, dependencies, onThen, onError) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useState(), result = _c[0], setResult = _c[1];
    useEffect(function () {
        var setParams = __assign({}, params[0]);
        Object.keys(setParams).forEach(function (key) {
            if (setParams[key] === undefined) {
                delete setParams[key];
            }
        });
        //console.log(apiCall)
        setLoading(true);
        apiCall(setParams)
            .catch(function (err) {
            if (onError) {
                onError(err);
            }
            setError(err);
        })
            .then(function (result) {
            //console.log(result)
            if (onThen) {
                onThen(result);
            }
            setResult(result);
        })
            .finally(function () { return setLoading(false); });
    }, __spreadArray([], dependencies, true));
    return [result, loading, error];
}
