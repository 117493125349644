var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function createQueryString(params) {
    return Object.keys(params)
        .map(function (key) { return key + "=" + params[key].toString(); })
        .join("&");
}
export function convertDBWorkflowToWorkflow(workflow) {
    return __assign(__assign({}, workflow), { id: workflow.UUID, time_created: new Date(Number(workflow.time_created) * 1000), time_lastModified: new Date(Number(workflow.time_lastModified) * 1000) });
}
