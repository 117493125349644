var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState } from 'react';
import { Box, Stack, Button, Modal, TextField, Select, InputAdornment, MenuItem, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { getGridStringOperators, getGridDateOperators, getGridSingleSelectOperators, DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { useNotifications, NotificationType } from 'common/context_providers/NotificationBoundary';
import { ApiClientContext } from 'common/api';
import { useApiCall } from 'common/hooks';
import { WorkflowStatus } from 'common/models';
import SortableTable from 'components/SortableTable';
import './SubmitRequest.css';
import WorkflowStatusChip from 'components/WorkflowStatusChip';
import Dropzone from 'react-dropzone';
var focusedStyle = {
    borderColor: '#2196f3'
};
function SubmitRequest() {
    var workflow_status_filterOps = getGridSingleSelectOperators();
    var dates_operations = ["is", "after", "onOrAfter", "before", "onOrBefore"];
    var dates_filterOps = getGridDateOperators().filter(function (a) { return dates_operations.includes(a.value); });
    var userTextInput_operations = ["equals", "contains"];
    var userTextInput_filterOps = getGridStringOperators().filter(function (a) { return userTextInput_operations.includes(a.value); });
    var submittedWorkflowsColumns = [
        {
            field: 'submitter_name',
            headerName: 'Submitter',
            sortable: false,
            filterable: false,
            flex: 2,
            filterOperators: userTextInput_filterOps
        },
        {
            field: 'patient',
            headerName: 'Patient',
            flex: 2,
            filterOperators: userTextInput_filterOps
        },
        {
            field: 'workflow_status',
            headerName: 'Workflow Status',
            flex: 1.5,
            renderCell: function (params) {
                var value = params.row.workflow_status;
                return (React.createElement(WorkflowStatusChip, { status: value }));
            },
            filterOperators: workflow_status_filterOps,
            valueOptions: Object.values(WorkflowStatus)
        },
        {
            field: 'time_created',
            headerName: 'Created',
            flex: 1,
            valueGetter: function (params) {
                var date = new Date(params.row.time_created);
                return date.toDateString();
            },
            filterOperators: dates_filterOps
        },
        {
            field: 'time_lastModified',
            headerName: 'Last Updated',
            flex: 1,
            valueGetter: function (params) {
                var date = new Date(params.row.time_lastModified);
                return date.toDateString();
            },
            filterOperators: dates_filterOps
        },
        {
            field: 'download_report',
            headerName: 'Report',
            sortable: false,
            filterable: false,
            flex: 1,
            align: 'center',
            renderCell: function (params) {
                var workflow_id = params.row.id;
                var onClick = function (e) {
                    e.stopPropagation(); // don't select this row after clicking
                    apiClient.getReportFile(workflow_id)
                        .catch(function (err) {
                        // log error
                    });
                };
                return params.row.workflow_status === WorkflowStatus.REPORT
                    || params.row.workflow_status === WorkflowStatus.BILLED
                    || params.row.workflow_status === WorkflowStatus.PAID ?
                    (React.createElement(Button, { onClick: onClick, disabled: params.row.workflow_status !== WorkflowStatus.REPORT
                            && params.row.workflow_status !== WorkflowStatus.BILLED
                            && params.row.workflow_status !== WorkflowStatus.PAID, variant: "contained", color: "secondary" }, "Download"))
                    : "";
            }
        }
    ];
    function getSortBy(sortModel) {
        if (sortModel.length > 0 && sortModel[0].field) {
            return sortModel[0].field;
        }
        return undefined;
    }
    function getSortDir(sortModel) {
        if (sortModel.length > 0 && sortModel[0].sort) {
            return sortModel[0].sort;
        }
        return undefined;
    }
    function getFilterStr(filterModel) {
        var filterItems = filterModel.items;
        var filterStr = '';
        filterItems.forEach(function (item) {
            filterStr = filterStr.concat(item.columnField, ' ' + item.operatorValue, ' ' + item.value);
        });
        return filterStr;
    }
    var navigate = useNavigate();
    var windowSmalLSizeQuery = useMediaQuery('(max-width:900px)');
    var sendNotification = useNotifications();
    var user = useAuth0().user;
    var apiClient = useContext(ApiClientContext);
    var _a = useState(''), gender = _a[0], setGender = _a[1];
    var handleGenderChange = function (event) {
        setGender(event.target.value);
    };
    var _b = useState([]), formFiles = _b[0], setFormFiles = _b[1];
    var _c = useState(false), openRequestModal = _c[0], setOpenRequestModal = _c[1];
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useState(10), pageSize = _e[0], setPageSize = _e[1];
    var _f = useState(false), uploading = _f[0], setUploading = _f[1];
    var _g = React.useState([
        { field: 'time_created', sort: 'asc' },
    ]), sortModel = _g[0], setSortModel = _g[1];
    var _h = React.useState({ "items": [{ columnField: '', operatorValue: '', value: '' }] }), filterModel = _h[0], setFilterModel = _h[1];
    var _j = useApiCall(apiClient.getWorkflows, [{
            pageSize: pageSize,
            page: page + 1,
            sortBy: getSortBy(sortModel),
            sortDirection: getSortDir(sortModel),
            filters: getFilterStr(filterModel),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }], [pageSize, page, sortModel, filterModel]), response = _j[0], loading = _j[1], errors = _j[2];
    // Undefiend behavior if user does not exist because this route is protected by auth. If this happens somethings gone wrong
    if (!user) {
        throw Error('Undefined User in Protected Route');
    }
    var userName = user.nickname ? user.nickname : user.username;
    var onSubmitCreateRequest = function (patient, gender, age, height, weight, client, submitter, files) {
        var workflowCreateRequestObj = { client: client, patient: patient, gender: gender, age: age, height: height, weight: weight, submitter: submitter };
        setUploading(true);
        apiClient.createWorkflow(workflowCreateRequestObj)
            .catch(function (err) {
            sendNotification(NotificationType.ERROR, "Failed to create workflow: ".concat(err.message));
        })
            .then(function (res) {
            if (res) {
                return res.json();
            }
            throw Error("Invalid response for create workflow. No workflow UUID returned");
        })
            .then(function (wfUUID) {
            return apiClient.uploadDicomFiles(wfUUID, files);
        })
            .then(function () {
            setUploading(false);
            window.location.reload();
        });
    };
    return (React.createElement("div", { className: "reactive-padding", style: { marginTop: "74px" } },
        React.createElement(Typography, { sx: { paddingTop: "10px", paddingLeft: "20px" }, variant: 'h4' }, "Analysis Submissions"),
        React.createElement(Stack, { className: "request-stack", direction: windowSmalLSizeQuery ? "column" : "row", style: { padding: 20 } },
            React.createElement(SortableTable, { loading: loading, paginationMode: "server", rowsPerPageOptions: [10, 20, 50, 100], page: page, pageSize: pageSize, rowCount: (response === null || response === void 0 ? void 0 : response.totalItemsCount) || 0, data: response === null || response === void 0 ? void 0 : response.data, rows: (response === null || response === void 0 ? void 0 : response.data) || [], columns: submittedWorkflowsColumns, onPageChange: function (page) { return setPage(page); }, onPageSizeChange: function (pageSize) { return setPageSize(pageSize); }, filterMode: "server", filterModel: filterModel, onFilterModelChange: setFilterModel, sortingMode: "server", sortModel: sortModel, onSortModelChange: setSortModel }),
            React.createElement(Box, { sx: { padding: '10px' } },
                React.createElement(Button, { variant: "contained", color: "secondary", onClick: function () { return setOpenRequestModal(true); } }, "New Request"))),
        React.createElement(Modal, { open: openRequestModal, onClose: function () { return setOpenRequestModal(false); }, className: "requestmodal" },
            React.createElement(Box, { sx: { transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', pt: 2, px: 4, pb: 3, minWidth: '600px' } },
                React.createElement("form", { onSubmit: function (e) {
                        e.preventDefault();
                        var form = e.target;
                        var patientInput = form.elements.namedItem('patient');
                        var ageInput = form.elements.namedItem('age');
                        var heightInput = form.elements.namedItem('height');
                        var weightInput = form.elements.namedItem('weight');
                        onSubmitCreateRequest(patientInput.value, gender, ageInput.value, heightInput.value, weightInput.value, user.org_id, user.sub, formFiles);
                    } },
                    React.createElement(Stack, { spacing: 2 },
                        React.createElement(Typography, { variant: "h4" }, "New Request"),
                        React.createElement(Typography, { variant: 'subtitle1', gutterBottom: true }, "Select DICOM files for analysis"),
                        React.createElement("hr", { style: { margin: '15px', marginBottom: '30px', borderColor: 'black', width: '-webkit-fill-available' } }),
                        React.createElement(TextField, { required: true, id: "patient", label: "Patient Identifier" }),
                        React.createElement(Select, { required: true, id: "gender", value: gender, label: "Gender", onChange: handleGenderChange },
                            React.createElement(MenuItem, { value: 'Male' }, "Male"),
                            React.createElement(MenuItem, { value: 'Female' }, "Female"),
                            React.createElement(MenuItem, { value: 'Other' }, "Other"),
                            React.createElement(MenuItem, { value: 'Not-Disclosed' }, "Not-Disclosed")),
                        React.createElement(TextField, { required: true, id: "age", label: "Age", type: "number" }),
                        React.createElement(TextField, { required: true, id: "height", label: "Height", type: "number", InputProps: {
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, "cm"),
                            } }),
                        React.createElement(TextField, { required: true, id: "weight", label: "Weight", type: "number", InputProps: {
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, "kg"),
                            } }),
                        React.createElement(Stack, { direction: "row", spacing: 2 },
                            React.createElement(Dropzone, { accept: { 'dicom/application': ['.dcm', '.dicom'] }, onDrop: function (acceptedFiles) {
                                    var newFiles = Array.from(acceptedFiles).reduce(function (prev, currVal, index, array) {
                                        for (var _i = 0, formFiles_1 = formFiles; _i < formFiles_1.length; _i++) {
                                            var file = formFiles_1[_i];
                                            //console.log(file.name)
                                            //console.log(file.type)
                                            if (file.name === currVal.name) {
                                                return prev;
                                            }
                                        }
                                        return __spreadArray(__spreadArray([], prev, true), [currVal], false);
                                    }, []);
                                    setFormFiles(__spreadArray(__spreadArray([], formFiles, true), newFiles, true));
                                } }, function (_a) {
                                var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, isFocused = _a.isFocused, isDragActive = _a.isDragActive;
                                return (React.createElement("div", __assign({}, getRootProps({ className: 'dropzone',
                                    style: __assign({}, (isFocused || isDragActive ? focusedStyle : {})) })),
                                    React.createElement("input", __assign({}, getInputProps())),
                                    React.createElement("p", null, "Drag 'n' drop some files/directories here, or click to select files"),
                                    React.createElement("em", null, "(Only DICOM files will be accepted)")));
                            }),
                            !uploading && formFiles && formFiles.length > 0 &&
                                React.createElement("div", { style: { display: 'flex', minHeight: '300px', maxHeight: '600px', width: '100%' } },
                                    React.createElement(DataGrid, { rows: formFiles, hideFooter: true, getRowId: function (row) { return row.name; }, columns: [
                                            { field: 'name', headerName: 'File Name', flex: 3 },
                                            {
                                                field: 'actions', type: 'actions', flex: 1, getActions: function (params) { return [
                                                    React.createElement(GridActionsCellItem, { icon: React.createElement(DeleteIcon, null), label: "Delete", onClick: function () { return setFormFiles(formFiles.filter(function (file) { return file.name !== params.row.name; })); } })
                                                ]; },
                                            },
                                        ] })),
                            uploading &&
                                React.createElement(CircularProgress, null)),
                        React.createElement(Button, { color: "secondary", variant: "contained", type: "submit" }, "Submit")))))));
}
export default SubmitRequest;
