import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';
import { getGridStringOperators, getGridDateOperators, getGridSingleSelectOperators } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { useNotifications } from 'common/context_providers/NotificationBoundary';
import { ApiClientContext } from 'common/api';
import { useApiCall } from 'common/hooks';
import { WorkflowStatus } from 'common/models';
import WorkflowStatusChip from 'components/WorkflowStatusChip';
import SortableTable from 'components/SortableTable';
import './SegAnalysisLanding.css';
function SegAnalysisLanding() {
    var workflow_status_filterOps = getGridSingleSelectOperators();
    var dates_operations = ["is", "after", "onOrAfter", "before", "onOrBefore"];
    var dates_filterOps = getGridDateOperators().filter(function (a) { return dates_operations.includes(a.value); });
    var userTextInput_operations = ["equals", "contains"];
    var userTextInput_filterOps = getGridStringOperators().filter(function (a) { return userTextInput_operations.includes(a.value); });
    var submittedWorkflowsColumns = [
        {
            field: 'org_id_name',
            headerName: 'Organization',
            sortable: false,
            filterable: false,
            flex: 2,
            filterOperators: userTextInput_filterOps
        },
        {
            field: 'submitter_name',
            headerName: 'Submitter',
            sortable: false,
            filterable: false,
            flex: 2,
            filterOperators: userTextInput_filterOps
        },
        {
            field: 'technician_name',
            headerName: 'Technician',
            sortable: false,
            filterable: false,
            flex: 2,
            filterOperators: userTextInput_filterOps
        },
        {
            field: 'patient',
            headerName: 'Patient',
            flex: 2,
            filterOperators: userTextInput_filterOps
        },
        {
            field: 'workflow_status',
            headerName: 'Workflow Status',
            flex: 1.5,
            renderCell: function (params) {
                var value = params.row.workflow_status;
                return (React.createElement(WorkflowStatusChip, { status: value }));
            },
            filterOperators: workflow_status_filterOps,
            valueOptions: Object.values(WorkflowStatus)
        },
        {
            field: 'time_created',
            headerName: 'Created',
            flex: 1,
            valueGetter: function (params) {
                var date = new Date(params.row.time_created);
                return date.toDateString();
            },
            filterOperators: dates_filterOps
        },
        {
            field: 'time_lastModified',
            headerName: 'Last Updated',
            flex: 1,
            valueGetter: function (params) {
                var date = new Date(params.row.time_lastModified);
                return date.toDateString();
            },
            filterOperators: dates_filterOps
        },
        {
            field: 'perform_analysis',
            headerName: 'View Analysis',
            sortable: false,
            flex: 1,
            align: 'center',
            renderCell: function (params) {
                var workflow_id = params.row.id;
                // workflow_id
                return (React.createElement(Button, { component: Link, to: "/analysis/".concat(workflow_id), variant: "contained", color: "secondary" }, "GO"));
            }
        },
        {
            field: 'download_report',
            headerName: 'Report',
            sortable: false,
            filterable: false,
            flex: 1,
            align: 'center',
            renderCell: function (params) {
                var workflow_id = params.row.id;
                var onClick = function (e) {
                    e.stopPropagation(); // don't select this row after clicking
                    apiClient.getReportFile(workflow_id)
                        .catch(function (err) {
                        // log error
                    });
                };
                return params.row.workflow_status === WorkflowStatus.REPORT
                    || params.row.workflow_status === WorkflowStatus.BILLED
                    || params.row.workflow_status === WorkflowStatus.PAID ?
                    (React.createElement(Button, { onClick: onClick, disabled: params.row.workflow_status !== WorkflowStatus.REPORT
                            && params.row.workflow_status !== WorkflowStatus.BILLED
                            && params.row.workflow_status !== WorkflowStatus.PAID, variant: "contained", color: "secondary" }, "Download"))
                    : "";
            }
        },
        {
            field: 'delete_workflow',
            headerName: 'Remove',
            sortable: false,
            filterable: false,
            flex: 1,
            align: 'center',
            renderCell: function (params) {
                var workflow_id = params.row.id;
                var onClick = function (e) {
                    e.stopPropagation(); // don't select this row after clicking
                    apiClient.deleteWorkflow(workflow_id)
                        .catch(function (err) {
                        // log error
                    })
                        .then(function () {
                        window.location.reload();
                    });
                };
                return (React.createElement(Button, { onClick: onClick, variant: "contained", color: "warning" }, "Delete"));
            }
        }
    ];
    function getSortBy(sortModel) {
        if (sortModel.length > 0 && sortModel[0].field) {
            return sortModel[0].field;
        }
        return undefined;
    }
    function getSortDir(sortModel) {
        if (sortModel.length > 0 && sortModel[0].sort) {
            return sortModel[0].sort;
        }
        return undefined;
    }
    function getFilterStr(filterModel) {
        var filterItems = filterModel.items;
        var filterStr = '';
        filterItems.forEach(function (item) {
            filterStr = filterStr.concat(item.columnField, ' ' + item.operatorValue, ' ' + item.value);
        });
        return filterStr;
    }
    var windowSmalLSizeQuery = useMediaQuery('(max-width:900px)');
    var sendNotification = useNotifications();
    var _a = useAuth0(), user = _a.user, getAccessTokenSilently = _a.getAccessTokenSilently, getAccessTokenWithPopup = _a.getAccessTokenWithPopup;
    var apiClient = useContext(ApiClientContext);
    var _b = useState([]), formFiles = _b[0], setFormFiles = _b[1];
    var _c = useState(false), openRequestModal = _c[0], setOpenRequestModal = _c[1];
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useState(10), pageSize = _e[0], setPageSize = _e[1];
    var _f = React.useState([
        { field: 'time_created', sort: 'asc' },
    ]), sortModel = _f[0], setSortModel = _f[1];
    var _g = React.useState({ "items": [{ columnField: '', operatorValue: '', value: '' }] }), filterModel = _g[0], setFilterModel = _g[1];
    var _h = useApiCall(apiClient.getWorkflows, [{
            pageSize: pageSize,
            page: page + 1,
            sortBy: getSortBy(sortModel),
            sortDirection: getSortDir(sortModel),
            filters: getFilterStr(filterModel),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }], [pageSize, page, sortModel, filterModel]), response = _h[0], loading = _h[1], errors = _h[2];
    useEffect(function () {
        //console.log(formFiles)
    }, [formFiles]);
    // Undefiend behavior if user does not exist because this route is protected by auth. If this happens somethings gone wrong
    if (!user) {
        throw Error('Undefined User in Protected Route');
    }
    return (React.createElement("div", { className: "reactive-padding", style: { marginTop: "74px" } },
        React.createElement(Typography, { sx: { paddingTop: "10px", paddingLeft: "20px" }, variant: 'h4' }, "Segmenation Analysis"),
        React.createElement(Typography, { sx: { paddingLeft: "20px" }, variant: 'subtitle1', gutterBottom: true }, "Select a workflow to perform analysis"),
        React.createElement(Stack, { className: "request-stack", direction: windowSmalLSizeQuery ? "column" : "row", style: { padding: 20 } },
            React.createElement(SortableTable, { loading: loading, paginationMode: "server", rowsPerPageOptions: [10, 20, 50, 100], page: page, pageSize: pageSize, rowCount: (response === null || response === void 0 ? void 0 : response.totalItemsCount) || 0, data: response === null || response === void 0 ? void 0 : response.data, rows: (response === null || response === void 0 ? void 0 : response.data) || [], columns: submittedWorkflowsColumns, onPageChange: function (page) { return setPage(page); }, onPageSizeChange: function (pageSize) { return setPageSize(pageSize); }, filterMode: "server", filterModel: filterModel, onFilterModelChange: setFilterModel, sortingMode: "server", sortModel: sortModel, onSortModelChange: setSortModel }))));
}
export default SegAnalysisLanding;
