var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createContext } from "react";
import { createQueryString, convertDBWorkflowToWorkflow, } from "common/api/utils";
import process from "process";
export var ApiClientContext = createContext(undefined);
var ApiClient = /** @class */ (function () {
    function ApiClient(getAccessTokenSilently, getAccessTokenPopup) {
        this._api_host = process.env.API_URL;
        this._audience = process.env.AUTH0_API_AUDIENCE || "audience";
        this._getAccessTokenSilently = getAccessTokenSilently;
        this._getAccessTokenPopup = getAccessTokenPopup;
        this._setTokenGetter();
        // Bind methods to instance of class
        this.getWorkflows = this.getWorkflows.bind(this);
        this.uploadDicomFiles = this.uploadDicomFiles.bind(this);
        this.createWorkflow = this.createWorkflow.bind(this);
    }
    ApiClient.prototype.updateTokenGetters = function (getAccessTokenSilently, getAccessTokenPopup) {
        this._getAccessTokenSilently = getAccessTokenSilently;
        this._getAccessTokenPopup = getAccessTokenPopup;
        this._setTokenGetter();
    };
    /*
    ===============================================================
                  API Calls
  
      - Don't forget to bind new api call functions
    ===============================================================
    */
    /* --------------------------------------------------------------------------------
    General request and task handling
    -------------------------------------------------------------------------------- */
    ApiClient.prototype.waitForTaskCompletion = function (task_id, token) {
        var min = 200;
        var max = 1000;
        var step = 500;
        var interval = 0;
        var self = this;
        return new Promise(function (resolve, reject) {
            function poll() {
                return __awaiter(this, void 0, void 0, function () {
                    var api_url, response, data, state, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                api_url = new URL("".concat(self._api_host, "/tasks/").concat(task_id));
                                return [4 /*yield*/, fetch(api_url.href, {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: "Bearer ".concat(token),
                                        },
                                    })];
                            case 1:
                                response = _a.sent();
                                return [4 /*yield*/, response.json()];
                            case 2:
                                data = _a.sent();
                                if (data) {
                                    state = data["state"];
                                    result = data["result"];
                                    switch (state) {
                                        case "SUCCESS":
                                            resolve(result);
                                            return [2 /*return*/];
                                        case "REJECTED":
                                            reject(new Error(result === "" ? "Asynchronous job ".concat(task_id, " was rejected") : result));
                                            return [2 /*return*/];
                                        case "FAILURE":
                                            reject(new Error(result === "" ? "Asynchronous job ".concat(task_id, " failed") : result));
                                            return [2 /*return*/];
                                        case "IGNORED":
                                            reject(new Error(result === "" ? "Asynchronous job ".concat(task_id, " was ignored") : result));
                                            return [2 /*return*/];
                                        case "REVOKED":
                                            reject(new Error(result === "" ? "Asynchronous job ".concat(task_id, " was revoked") : result));
                                            return [2 /*return*/];
                                        default:
                                            break;
                                    }
                                    interval = min;
                                }
                                else {
                                    interval = Math.min(interval + step, max);
                                }
                                // Recursive call
                                setTimeout(poll, interval);
                                return [2 /*return*/];
                        }
                    });
                });
            }
            // First call
            setTimeout(poll, interval);
        });
    };
    /* ---------------------------------------------------------------
    Workflows => /api/workflows
    --------------------------------------------------------------- */
    // POST -> /api/workflows
    ApiClient.prototype.createWorkflow = function (workflowData) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer ".concat(token),
                                },
                                body: JSON.stringify({ params: workflowData }),
                            })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    // GET -> /api/workflows/<workflow_id>
    ApiClient.prototype.getWorkflow = function (workflow_id, options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response, resJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        resJson = _a.sent();
                        return [2 /*return*/, convertDBWorkflowToWorkflow(resJson)];
                }
            });
        });
    };
    // GET -> /api/workflows?queryparams
    ApiClient.prototype.getWorkflows = function (options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, queryStr, response, resJson, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows"));
                        queryStr = createQueryString(options);
                        api_url.search = queryStr;
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        resJson = _a.sent();
                        data = resJson.data.map(function (workflow) {
                            return convertDBWorkflowToWorkflow(workflow);
                        });
                        return [2 /*return*/, __assign(__assign({}, resJson), { data: data })];
                }
            });
        });
    };
    // DELETE -> /api/workflows/<workflow_id>
    ApiClient.prototype.deleteWorkflow = function (workflow_id, options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "DELETE",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /* ---------------------------------------------------------------
    Dicoms => /api/workflows/<workflow_id>/dicoms
    --------------------------------------------------------------- */
    // POST -> /api/workflows/<workflow_id>/dicoms
    ApiClient.prototype.uploadDicomFiles = function (workflow_id, files) {
        return __awaiter(this, void 0, void 0, function () {
            var numSave, numLeft, numFilesPerUpload, allData, data, numToUpload, j;
            var _this = this;
            return __generator(this, function (_a) {
                if (files.length <= 0) {
                    console.warn("No files sent to be uploaded");
                    return [2 /*return*/];
                }
                numSave = 0;
                numLeft = files.length;
                numFilesPerUpload = 16;
                allData = [];
                while (numLeft > 0) {
                    data = new FormData();
                    numToUpload = Math.min(numLeft, numFilesPerUpload);
                    for (j = 0; j < numToUpload; j++, numSave++)
                        data.append(files[numSave].name, files[numSave]);
                    numLeft = numLeft - numToUpload;
                    allData.push(data);
                }
                return [2 /*return*/, this.getToken()
                        .then(function (token) {
                        var uploadFormData = function (data) {
                            var api_url = new URL("".concat(_this._api_host, "/workflows/").concat(workflow_id, "/dicoms"));
                            return fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                                body: data,
                            });
                        };
                        var delay = 0;
                        var delayIncrement = 50;
                        var promiseChain = allData.map(function (data) {
                            delay += delayIncrement;
                            return new Promise(function (resolve) { return setTimeout(resolve, delay); }).then(function () { return uploadFormData(data); });
                        });
                        return Promise.all(promiseChain);
                    })
                        .then(function () {
                        return _this.sequenceWorkflow(workflow_id);
                    })];
            });
        });
    };
    // GET -> /api/workflows/<workflow_id>/dicoms/<dicom_id>
    ApiClient.prototype.getDicomFile = function (workflow_id, dicom_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response, imgBlob;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/dicoms/").concat(dicom_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.blob()];
                    case 3:
                        imgBlob = _a.sent();
                        return [2 /*return*/, URL.createObjectURL(imgBlob)];
                }
            });
        });
    };
    // GET -> /api/workflows/<workflow_id>/dicoms
    ApiClient.prototype.getDicomZip = function (workflow_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/dicoms"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // DELETE -> /api/workflows/<workflow_id>/dicoms
    // NOT IMPLEMENTED
    // DELETE -> /api/workflows/<workflow_id>/dicoms/<dicom_id>
    // NOT IMPLEMENTED
    /* ---------------------------------------------------------------
    Sequences => /api/workflows/<workflow_id>/sequences
    --------------------------------------------------------------- */
    // POST -> /api/workflows/<workflow_id>/sequences
    ApiClient.prototype.sequenceWorkflow = function (workflow_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/sequences"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    // GET -> /api/workflows/<workflow_id>/sequences
    // NOT IMPLEMENTED
    // GET -> /api/workflows/<workflow_id>/sequences/<sequence_id>
    // NOT IMPLEMENTED
    // DELETE -> /api/workflows/<workflow_id>/sequences
    // NOT IMPLEMENTED
    /* ---------------------------------------------------------------
    Segmentations => /api/workflows/<workflow_id>/segmentations
    --------------------------------------------------------------- */
    // POST -> /api/workflows/<workflow_id>/segmentations
    ApiClient.prototype.createSegmentation = function (workflow_id, sequence_id, segmentationLocation, circle) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response, job_id, segmentation_id, vtf_result, segmentation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/segmentations"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer ".concat(token),
                                },
                                body: JSON.stringify({
                                    params: {
                                        sequence: sequence_id,
                                        location: segmentationLocation,
                                        numPoint: 6,
                                        x: circle.x,
                                        y: circle.y,
                                        r: circle.radius,
                                        viewDim: circle.viewDim,
                                        removeBkgd: false
                                    },
                                })
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        job_id = _a.sent();
                        return [4 /*yield*/, this.waitForTaskCompletion(job_id, token)];
                    case 4:
                        segmentation_id = _a.sent();
                        return [4 /*yield*/, this.calculateVtfs(workflow_id)];
                    case 5:
                        vtf_result = _a.sent();
                        return [4 /*yield*/, this.getSegmentation(workflow_id, segmentation_id)];
                    case 6:
                        segmentation = _a.sent();
                        return [2 /*return*/, segmentation];
                }
            });
        });
    };
    // PATCH -> /api/workflows/<workflow_id>/segmentations/<segmentation_id>
    ApiClient.prototype.patchSegmentation = function (workflow_id, segmentation_id, overridePoints, segmentationLocation) {
        return __awaiter(this, void 0, void 0, function () {
            var token, numSteps, points, api_url, response, job_id, segmentation_result, vtf_result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        numSteps = Object === null || Object === void 0 ? void 0 : Object.keys(overridePoints).map(function (key) { return (Number(key) + 1); });
                        points = numSteps.reduce(function (aggr, step) {
                            var xys = overridePoints[String(step - 1)].reduce(function (stepPnts, pointArr) {
                                return __spreadArray(__spreadArray([], stepPnts, true), [pointArr[0], pointArr[1]], false);
                            }, []);
                            return __spreadArray(__spreadArray([], aggr, true), xys, true);
                        }, []);
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/segmentations/").concat(segmentation_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer ".concat(token),
                                },
                                body: JSON.stringify({
                                    params: {
                                        points: points,
                                        numSteps: numSteps,
                                        location: segmentationLocation,
                                        numPoint: 6,
                                        removeBkgd: false
                                    }
                                })
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        job_id = _a.sent();
                        return [4 /*yield*/, this.waitForTaskCompletion(job_id, token)];
                    case 4:
                        segmentation_result = _a.sent();
                        return [4 /*yield*/, this.calculateVtfs(workflow_id)];
                    case 5:
                        vtf_result = _a.sent();
                        // Finally get the segmentation data
                        // apparently updates do NOT get data back from server, just assume updated...
                        return [2 /*return*/, segmentation_result];
                }
            });
        });
    };
    // GET -> /api/workflows/<workflow_id>/segmentations
    // NOT IMPLEMENTED
    // GET -> /api/workflows/<workflow_id>/segmentations/<segmentation_id>
    ApiClient.prototype.getSegmentation = function (workflow_id, segmentation_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response, resJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/segmentations/").concat(segmentation_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        resJson = _a.sent();
                        return [2 /*return*/, __assign(__assign({}, resJson), { id: segmentation_id })];
                }
            });
        });
    };
    // DELETE -> /api/workflows/<workflow_id>/segmentations
    // NOT IMPLEMENTED
    // DELETE -> /api/workflows/<workflow_id>/segmentations/<segmentation_id>
    ApiClient.prototype.deleteSegmentation = function (workflow_id, segmentation_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/segmentations/").concat(segmentation_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "DELETE",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /* ---------------------------------------------------------------
    VTFs => /api/workflows/<workflow_id>/vtfs
    --------------------------------------------------------------- */
    // POST -> /api/workflows/<workflow_id>/vtfs
    ApiClient.prototype.calculateVtfs = function (workflow_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/vtfs"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // GET -> /api/workflows/<workflow_id>/vtfs
    // NOT IMPLEMENTED
    // GET -> /api/workflows/<workflow_id>/vtfs/<vtf_id>
    // NOT IMPLEMENTED
    // DELETE -> /api/workflows/<workflow_id>/vtfs
    // NOT IMPLEMENTED
    /* ---------------------------------------------------------------
    Report => /api/workflows/<workflow_id>/report
    --------------------------------------------------------------- */
    // POST => /api/workflows/<workflow_id>/report
    ApiClient.prototype.generateReport = function (workflow_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/report"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: 
                    // Report generation is completed by a celery job, therefore job_id is returned
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // GET => /api/workflows/<workflow_id>/report/data/<data_id>
    ApiClient.prototype.getReportData = function (workflow_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, data_id, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        data_id = "00000000-0000-0000-0000-00000000000D";
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/report/").concat(data_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    // GET => /api/workflows/<workflow_id>/report/<file_id>
    ApiClient.prototype.getReportFile = function (workflow_id) {
        return __awaiter(this, void 0, void 0, function () {
            var token, file_id, api_url, response, fileBlob, url, link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        file_id = "00000000-0000-0000-0000-00000000000F";
                        api_url = new URL("".concat(this._api_host, "/workflows/").concat(workflow_id, "/report/").concat(file_id));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                    'Content-Type': 'application/pdf',
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.blob()];
                    case 3:
                        fileBlob = _a.sent();
                        url = window.URL.createObjectURL(new Blob([fileBlob]));
                        link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', "ArcusReport.pdf");
                        // Append to html link element page
                        document.body.appendChild(link);
                        // Start download
                        link.click();
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                        return [2 /*return*/];
                }
            });
        });
    };
    // DELETE => /api/workflows/<workflow_id>/report
    // NOT IMPLEMENTED
    /* ---------------------------------------------------------------
    Issues => /api/issues
    --------------------------------------------------------------- */
    // POST => /api/issues
    ApiClient.prototype.submitIssue = function (issueData) {
        return __awaiter(this, void 0, void 0, function () {
            var token, api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getToken()];
                    case 1:
                        token = _a.sent();
                        api_url = new URL("".concat(this._api_host, "/issues"));
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer ".concat(token),
                                },
                                body: JSON.stringify({ params: issueData }),
                            })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /*
    ===============================================================
                  End of Authorized calls
    ===============================================================
    */
    /* ---------------------------------------------------------------
    Transactions => /api/transactions
    --------------------------------------------------------------- */
    /* ---------------------------------------------------------------
    Clients => this is stored in Auth0 metadata
    --------------------------------------------------------------- */
    /* ---------------------------------------------------------------
    Users => this is stored in Auth0 metadata
    --------------------------------------------------------------- */
    /* ---------------------------------------------------------------
    UP => this is an API healthcheck, it just returns success when up
    --------------------------------------------------------------- */
    ApiClient.prototype.isApiUp = function () {
        return __awaiter(this, void 0, void 0, function () {
            var api_url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("In isApiUp");
                        console.log(this._api_host);
                        api_url = new URL("".concat(this._api_host, "/up"));
                        console.log(api_url.href);
                        return [4 /*yield*/, fetch(api_url.href, {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /*
    ===============================================================
                  End of API calls
    ===============================================================
    */
    /*
    ===============================================================
                  Don't touch this
    ===============================================================
    */
    ApiClient.prototype._setTokenGetter = function () {
        var _this = this;
        this.getToken = function () {
            return _this._getToken(_this._getAccessTokenSilently, _this._getAccessTokenPopup);
        };
    };
    ApiClient.prototype._getToken = function (getAccessTokenSilently, getAccessTokenPopup) {
        return __awaiter(this, void 0, void 0, function () {
            var audience;
            return __generator(this, function (_a) {
                audience = this._audience;
                return [2 /*return*/, getAccessTokenSilently({ audience: audience })
                        .catch(function (err) {
                        console.warn("Unable to get access token silently. Request consent. ".concat(err));
                        return getAccessTokenPopup({ audience: audience });
                    })
                        .catch(function (err) {
                        console.warn("Unable to retrieve access token");
                        console.error(err);
                        throw Error("Unable to retrieve access token: ".concat(err));
                    })];
            });
        });
    };
    return ApiClient;
}());
export { ApiClient };
