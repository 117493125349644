import React from 'react';
import { AppBar, Box, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArcusLogo from 'public/arcus-logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import routes from 'routing/routes';
import ProfileButton from 'components/ProfileButton';
var filterRoute = function (roles) { return function (route) {
    if (!route.inDrawer) {
        return false;
    }
    if (!route.restricted) {
        return true;
    }
    var sharedperms = roles.filter(function (n) { return route.restricted.indexOf(n) !== -1; });
    return sharedperms.length > 0;
}; };
var MenuItems = function (props) {
    var user = props.user, isUserLoading = props.isUserLoading, isUserAuthenticated = props.isUserAuthenticated;
    if (isUserLoading || !isUserAuthenticated) {
        return (React.createElement(Box, null));
    }
    var ROLES = process.env.AUTH0_CLAIM_ROLES;
    var userRoles = user[ROLES];
    return (React.createElement(Box, null, routes.filter(filterRoute(userRoles)).map(function (route, index) { return (React.createElement(Link, { key: index, to: route.path },
        React.createElement(Button, { variant: "text", sx: { color: "primary.contrastText", marginLeft: 3 } }, route.name))); })));
};
function NavBar() {
    var _a = useAuth0(), user = _a.user, isLoading = _a.isLoading, isAuthenticated = _a.isAuthenticated;
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { position: "fixed" },
            React.createElement(Toolbar, null,
                React.createElement(Link, { to: "/" },
                    React.createElement("img", { src: ArcusLogo, width: "120px" })),
                React.createElement(MenuItems, { user: user, isUserLoading: isLoading, isUserAuthenticated: isAuthenticated }),
                React.createElement("span", { style: { flexGrow: 1 } }),
                !isLoading && React.createElement(ProfileButton, null)))));
}
export default NavBar;
