var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
export var AuthButtons;
(function (AuthButtons) {
    AuthButtons["LOGIN"] = "Login";
    AuthButtons["LOGOUT"] = "Logout";
    AuthButtons["BOTH"] = "Both";
})(AuthButtons || (AuthButtons = {}));
export var LoginButton = function (props) {
    var loginWithRedirect = useAuth0().loginWithRedirect;
    var variant = props.variant, color = props.color;
    var variantString = variant;
    var variantSetting = variantString;
    return (React.createElement(Button, { variant: variantSetting, sx: { color: color }, onClick: function () { return loginWithRedirect(); } }, "SSO Login"));
};
export var LogoutButton = function (props) {
    var logout = useAuth0().logout;
    var variant = props.variant, color = props.color;
    var variantString = variant;
    var variantSetting = variantString;
    return (React.createElement(Button, { variant: variantSetting, sx: { color: color }, onClick: function () { return logout({
            returnTo: window.location.origin,
        }); } }, "Logout"));
};
var AuthenticationButton = function (props) {
    var isAuthenticated = useAuth0().isAuthenticated;
    var showButton = props.showButton;
    if (showButton === AuthButtons.LOGIN && !isAuthenticated) {
        return React.createElement(LoginButton, __assign({}, props));
    }
    else if (showButton === AuthButtons.LOGOUT && isAuthenticated) {
        React.createElement(LogoutButton, __assign({}, props));
    }
    else if (showButton === AuthButtons.BOTH) {
        return isAuthenticated ? React.createElement(LogoutButton, __assign({}, props)) : React.createElement(LoginButton, __assign({}, props));
    }
};
export default AuthenticationButton;
