var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useContext } from "react";
import * as zip from "@zip.js/zip.js";
var SequenceContext = createContext(null);
function blobToBase64(blob) {
    return new Promise(function (resolve, _) {
        var reader = new FileReader();
        reader.onloadend = function () { return resolve(reader.result); };
        reader.readAsDataURL(blob);
    });
}
var model = (function () {
    return {
        getEntries: function (file, options) {
            return new zip.ZipReader(new zip.HttpReader(file, { preventHeadRequest: true })).getEntries(options);
        },
        getBase64: function (entry, options) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, entry.getData(new zip.BlobWriter(), options)];
                        case 1:
                            data = _a.sent();
                            return [4 /*yield*/, blobToBase64(data)];
                        case 2: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
    };
})();
export function SequenceProvider(_a) {
    var _this = this;
    var children = _a.children;
    var encoding = "cp437";
    var vesselsData = [
        {
            name: "LT-PROX",
            className: "vessel-1",
            color: "#F38C8C",
        },
        {
            name: "LT-DIST",
            className: "vessel-2",
            color: "#98DFE2",
        },
        {
            name: "RT-PROX",
            className: "vessel-3",
            color: "#F7AB7E",
        },
        {
            name: "RT-DIST",
            className: "vessel-4",
            color: "#807EF7",
        },
        {
            name: "MAIN-PA",
            className: "vessel-5",
            color: "#EC5141",
        },
        {
            name: "MAIN-Ao",
            className: "vessel-6",
            color: "#7CABE6",
        },
        {
            name: "ASC-Ao",
            className: "vessel-7",
            color: "#60db71",
        },
        {
            name: "DSC-Ao",
            className: "vessel-8",
            color: "#e3e162",
        },
        {
            name: "Other-1",
            className: "vessel-9",
            color: "#62e3b6",
        },
        {
            name: "Other-2",
            className: "vessel-10",
            color: "#c0ed74",
        },
    ];
    var ddSegmentionOptions = [
        {
            label: "Run Segmentation",
            key: "run",
        },
        {
            label: "Re-process",
            key: "process",
        },
    ];
    var getSequenceImages = function (currentSequenceData) { return __awaiter(_this, void 0, void 0, function () {
        var magnitude, phase, MagEntries, PhaseEntries, allPhaseImages, allMagImages;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    magnitude = currentSequenceData.magnitude, phase = currentSequenceData.phase;
                    return [4 /*yield*/, model.getEntries("".concat(magnitude.images), {
                            filenameEncoding: encoding,
                        })];
                case 1:
                    MagEntries = _a.sent();
                    return [4 /*yield*/, model.getEntries("".concat(phase.images), {
                            filenameEncoding: encoding,
                        })];
                case 2:
                    PhaseEntries = _a.sent();
                    return [4 /*yield*/, Promise.all(PhaseEntries.map(function (entry) { return __awaiter(_this, void 0, void 0, function () {
                            var img;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, model.getBase64(entry)];
                                    case 1:
                                        img = _a.sent();
                                        return [2 /*return*/, img];
                                }
                            });
                        }); }))];
                case 3:
                    allPhaseImages = _a.sent();
                    return [4 /*yield*/, Promise.all(MagEntries.map(function (entry) { return __awaiter(_this, void 0, void 0, function () {
                            var img;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, model.getBase64(entry)];
                                    case 1:
                                        img = _a.sent();
                                        return [2 /*return*/, img];
                                }
                            });
                        }); }))];
                case 4:
                    allMagImages = _a.sent();
                    return [2 /*return*/, {
                            phase: allPhaseImages,
                            magnitude: allMagImages,
                        }];
            }
        });
    }); };
    var isLabelCurrentlyUsed = function (vesselName, seriesAssociation) {
        var _a;
        if (seriesAssociation &&
            ((_a = seriesAssociation.filter(function (association) {
                return association.associatedTo &&
                    association.associatedTo.name === vesselName;
            })) === null || _a === void 0 ? void 0 : _a.length)) {
            return true;
        }
        else
            return false;
    };
    var isImageSeriesLabelled = function (currentSequenceData, seriesAssociation) {
        var _a;
        var currentMriLabel = currentSequenceData.mriLabel;
        if ((_a = seriesAssociation.filter(function (association) {
            return association.associatedSeries &&
                association.associatedSeries.mriLabel === currentMriLabel;
        })) === null || _a === void 0 ? void 0 : _a.length) {
            return true;
        }
        else
            return false;
    };
    var findAssociationBySeries = function (currentMriLabel, seriesAssociation) {
        return seriesAssociation.filter(function (association, i) {
            return association.associatedSeries &&
                association.associatedSeries.mriLabel === currentMriLabel;
        })[0];
    };
    var findAssociationByVessel = function (vesselName, seriesAssociation) {
        return seriesAssociation.filter(function (association, i) {
            return association.associatedSeries &&
                association.associatedTo.name === vesselName;
        })[0];
    };
    var associatedVesselIndex = function (vesselName, currentSequenceData, seriesAssociation) {
        if (!isLabelCurrentlyUsed(vesselName, seriesAssociation)) {
            return;
        }
        else {
            return vesselsData.findIndex(function (vessel) { return vessel.name === vesselName; });
        }
    };
    var isVesselProcessed = function (vesselName, seriesAssociation) {
        var _a;
        var association = seriesAssociation.filter(function (association) { return association.associatedTo.name === vesselName; })[0];
        if (association && ((_a = association.segmentation) === null || _a === void 0 ? void 0 : _a.length)) {
            return true;
        }
        else {
            return false;
        }
    };
    var isVesselCompleted = function (vesselName, seriesAssociation) {
        var association = seriesAssociation.filter(function (association) { return association.associatedTo.name === vesselName; })[0];
        if (!association)
            return;
        return association.completed;
    };
    var value = {
        isVesselCompleted: isVesselCompleted,
        findAssociationByVessel: findAssociationByVessel,
        isVesselProcessed: isVesselProcessed,
        findAssociationBySeries: findAssociationBySeries,
        associatedVesselIndex: associatedVesselIndex,
        isLabelCurrentlyUsed: isLabelCurrentlyUsed,
        isImageSeriesLabelled: isImageSeriesLabelled,
        vesselsData: vesselsData,
        ddSegmentionOptions: ddSegmentionOptions,
        getSequenceImages: getSequenceImages
    };
    return (React.createElement(SequenceContext.Provider, { value: value }, children));
}
export function useSquence() {
    return useContext(SequenceContext);
}
