export var WorkflowStatus;
(function (WorkflowStatus) {
    WorkflowStatus["INITIATED"] = "Initiated";
    WorkflowStatus["DICOMS"] = "DICOMs Uploaded";
    WorkflowStatus["SEQUENCES"] = "DICOMs Sequenced";
    WorkflowStatus["TECHNICIAN_ASSIGNED"] = "Technician Assigned";
    WorkflowStatus["SEGMENTATIONS"] = "Vessels Segmented";
    WorkflowStatus["COMPLETE"] = "Analysis Complete";
    WorkflowStatus["REPORT"] = "Report Generated";
    WorkflowStatus["BILLED"] = "Billed";
    WorkflowStatus["PAID"] = "Paid";
    WorkflowStatus["CANCELED"] = "Canceled";
    WorkflowStatus["UNKNOWN"] = "Unknown";
    WorkflowStatus["ERROR"] = "Error";
})(WorkflowStatus || (WorkflowStatus = {}));
