import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Stack, Box, Typography, FormControl, TextField, Select, InputLabel, MenuItem, Button, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiClientContext } from 'common/api';
import { useNotifications, NotificationType } from 'common/context_providers/NotificationBoundary';
import './SubmitIssue.css';
function SubmitIssue() {
    var windowSmalLSizeQuery = useMediaQuery('(max-width:900px)');
    // Undefiend behavior if user does not exist because this route is protected by auth.
    // If this happens somethings gone wrong
    var user = useAuth0().user;
    if (!user) {
        throw Error('Undefined User in Protected Route');
    }
    var apiClient = useContext(ApiClientContext);
    var sendNotification = useNotifications();
    var navigate = useNavigate();
    var _a = useState(false), uploading = _a[0], setUploading = _a[1];
    var _b = useState(''), concern = _b[0], setConcern = _b[1];
    var handleConcernChange = function (event) {
        setConcern(event.target.value);
    };
    var _c = useState(''), frequency = _c[0], setFrequency = _c[1];
    var handleFrequencyChange = function (event) {
        setFrequency(event.target.value);
    };
    var _d = useState(''), severity = _d[0], setSeverity = _d[1];
    var handleSeverityChange = function (event) {
        setSeverity(event.target.value);
    };
    var _e = useState(''), urgency = _e[0], setUrgency = _e[1];
    var handleUrgencyChange = function (event) {
        setUrgency(event.target.value);
    };
    var _f = useState(""), emailValue = _f[0], setEmailValue = _f[1]; //user.email
    var _g = useState(false), emailValid = _g[0], setEmailValid = _g[1];
    var handleEmailChange = function (e) {
        var reg = new RegExp("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}");
        setEmailValid(reg.test(e.target.value));
        setEmailValue(e.target.value);
    };
    var _h = useState(""), phoneMobileValue = _h[0], setPhoneMobileValue = _h[1]; //user.phone_number
    var _j = useState(false), phoneMobileValid = _j[0], setPhoneMobileValid = _j[1];
    var handlePhoneMobileChange = function (e) {
        var reg = new RegExp("(^[+0-9]{1,3})*([0-9\-]{10,11}$)");
        setPhoneMobileValid(reg.test(e.target.value));
        setPhoneMobileValue(e.target.value);
    };
    var _k = useState(""), phonePrimaryValue = _k[0], setPhonePrimaryValue = _k[1];
    var _l = useState(false), phonePrimaryValid = _l[0], setPhonePrimaryValid = _l[1];
    var handlePhonePrimaryChange = function (e) {
        var reg = new RegExp("(^[+0-9]{1,3})*([0-9\-]{10,11}$)");
        setPhonePrimaryValid(reg.test(e.target.value));
        setPhonePrimaryValue(e.target.value);
    };
    var onSubmitCreateRequest = function (title, concern, description, frequency, severity, urgency, email, text, phone) {
        var issuePayload = { title: title, concern: concern, description: description, frequency: frequency, severity: severity, urgency: urgency, "contact": { email: email, text: text, phone: phone }, "encode": 10 };
        setUploading(true);
        apiClient.submitIssue(issuePayload)
            .catch(function (err) {
            sendNotification(NotificationType.ERROR, "Failed to submit issue: ".concat(err.message));
        })
            .then(function (res) {
            if (res) {
                return res.json();
            }
            throw Error("Invalid response for create issue. No issue UUID returned");
        })
            .then(function (resJson) {
            setUploading(false);
            // if success, show message (up to a minute), pause 2 seconds, then navigate back to home screen
            if (resJson) {
                sendNotification(NotificationType.SUCCESS, "Issue # ".concat(resJson, " submitted successfully."), 60000);
                setTimeout(function () { navigate("/"); }, 2000);
                return;
            }
            throw Error("Invalid response for create issue. No issue # returned");
        });
    };
    return (React.createElement("div", { className: "reactive-padding", style: { marginTop: "74px" } },
        React.createElement(Typography, { sx: { paddingTop: "10px", paddingLeft: "20px" }, variant: 'h4' }, "Submit an Issue"),
        React.createElement(Typography, { sx: { paddingLeft: "20px" }, variant: 'subtitle1', gutterBottom: true }, "Please provide the details of your issue"),
        React.createElement("form", { onSubmit: function (e) {
                e.preventDefault();
                var form = e.target;
                // MUI TextField doesn't handle regex validation???
                // manually validating, clear value, and exit submission if invalid
                if (!emailValid) {
                    setEmailValue("");
                    return;
                }
                if (!phoneMobileValid) {
                    setPhoneMobileValue("");
                    return;
                }
                if (!phonePrimaryValid) {
                    setPhonePrimaryValue("");
                    return;
                }
                var title = form.elements.namedItem('title');
                var concern = form.elements.namedItem('concern');
                var description = form.elements.namedItem('description');
                onSubmitCreateRequest(title.value, concern.value, description.value, frequency, severity, urgency, emailValue, phoneMobileValue, phonePrimaryValue);
            } },
            React.createElement(Stack, { className: "issue-stack", spacing: 2, direction: "column", style: { padding: 20 } },
                React.createElement(TextField, { required: true, disabled: true, id: "user", label: "User Login", defaultValue: user.name }),
                React.createElement(TextField, { required: true, id: "title", label: "Title", inputProps: { maxLength: 63 } }),
                React.createElement(FormControl, { sx: { m: 1, minWidth: 120 } },
                    React.createElement(InputLabel, { id: "concern-select-label" }, "Area of Concern *"),
                    React.createElement(Select, { required: true, labelId: "concern-select-label", id: "concern", value: concern, label: "Concern", onChange: handleConcernChange },
                        React.createElement(MenuItem, { value: 'Critical' }, "Submit Request"),
                        React.createElement(MenuItem, { value: 'Other' }, "Perform Analysis"),
                        React.createElement(MenuItem, { value: 'Other' }, "Report Issue"),
                        React.createElement(MenuItem, { value: 'Other' }, "Documentation"),
                        React.createElement(MenuItem, { value: 'Other' }, "Other"))),
                React.createElement(TextField, { required: true, id: "description", label: "Description", multiline: true, maxRows: 8, inputProps: { maxLength: 4095 } }),
                React.createElement(FormControl, { sx: { m: 1, minWidth: 120 } },
                    React.createElement(InputLabel, { id: "frequency-select-label" }, "Frequency *"),
                    React.createElement(Select, { required: true, labelId: "frequency-select-label", id: "frequency", value: frequency, label: "Frequency", onChange: handleFrequencyChange },
                        React.createElement(MenuItem, { value: 'Common' }, "Common"),
                        React.createElement(MenuItem, { value: 'Occasional' }, "Occasional"),
                        React.createElement(MenuItem, { value: 'Unusual' }, "Unusual"),
                        React.createElement(MenuItem, { value: 'Other' }, "Other"))),
                React.createElement(FormControl, { sx: { m: 1, minWidth: 120 } },
                    React.createElement(InputLabel, { id: "severity-select-label" }, "Severity *"),
                    React.createElement(Select, { required: true, labelId: "severity-select-label", id: "severity", value: severity, label: "Severity", onChange: handleSeverityChange },
                        React.createElement(MenuItem, { value: 'Critical' }, "Critical"),
                        React.createElement(MenuItem, { value: 'Major' }, "Major"),
                        React.createElement(MenuItem, { value: 'Minor' }, "Minor"),
                        React.createElement(MenuItem, { value: 'Other' }, "Other"))),
                React.createElement(FormControl, { sx: { m: 1, minWidth: 120 } },
                    React.createElement(InputLabel, { id: "urgency-select-label" }, "Urgency *"),
                    React.createElement(Select, { required: true, id: "urgency", value: urgency, label: "Urgency", onChange: handleUrgencyChange },
                        React.createElement(MenuItem, { value: 'Blocker' }, "Blocker"),
                        React.createElement(MenuItem, { value: 'Critical' }, "Critical"),
                        React.createElement(MenuItem, { value: 'Major' }, "Major"),
                        React.createElement(MenuItem, { value: 'Minor' }, "Minor"),
                        React.createElement(MenuItem, { value: 'Trivial' }, "Trivial"),
                        React.createElement(MenuItem, { value: 'Other' }, "Other"))),
                React.createElement(TextField, { required: true, id: "email", label: "Email", value: emailValue, onChange: handleEmailChange, error: !emailValid }),
                React.createElement(TextField, { required: true, id: "text", label: "Mobile Phone", value: phoneMobileValue, onChange: handlePhoneMobileChange, error: !phoneMobileValid }),
                React.createElement(TextField, { required: true, id: "phone", label: "Primary Phone", value: phonePrimaryValue, onChange: handlePhonePrimaryChange, error: !phonePrimaryValid }),
                !uploading &&
                    React.createElement(Button, { color: "secondary", variant: "contained", type: "submit" }, "Submit"),
                uploading &&
                    React.createElement(Box, { style: { textAlign: "center" } },
                        React.createElement(CircularProgress, null))))));
}
export default SubmitIssue;
