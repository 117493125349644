import React, { useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';
export var NotificationType;
(function (NotificationType) {
    NotificationType["INFO"] = "info";
    NotificationType["WARNING"] = "warning";
    NotificationType["ERROR"] = "error";
    NotificationType["SUCCESS"] = "success";
})(NotificationType || (NotificationType = {}));
var notificationState = {
    loading: false,
    type: NotificationType.INFO
};
var NotificationContext = React.createContext({
    sendNotification: function () { }
});
var NotificationBoundary = function (props) {
    var defaultDuration = 5000;
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(NotificationType.INFO), type = _b[0], setType = _b[1];
    var _c = useState(defaultDuration), duration = _c[0], setDuration = _c[1];
    var _d = useState(''), message = _d[0], setMessage = _d[1];
    var sendNotification = function (type, message, duration) {
        setIsOpen(true);
        setType(type);
        setMessage(message);
        if (duration) {
            setDuration(duration);
        }
    };
    var handleClose = function () {
        setIsOpen(false);
        if (duration !== defaultDuration) {
            setDuration(defaultDuration);
        }
    };
    return (React.createElement(NotificationContext.Provider, { value: { sendNotification: sendNotification } },
        React.createElement(Snackbar, { anchorOrigin: { vertical: 'top', horizontal: 'center' }, open: isOpen, autoHideDuration: duration, message: message, onClose: handleClose },
            React.createElement(Alert, { onClose: handleClose, severity: type, sx: { width: '100%' } }, message)),
        props.children));
};
export var useNotifications = function () {
    var notifContext = useContext(NotificationContext);
    return notifContext.sendNotification;
};
export default NotificationBoundary;
